import React, { useEffect, useState } from 'react'
import "./PreferredLocation.scss"
import Loader from '../../components/Loader/Loader'
import Select from 'react-select'
import { getAllCountry, getUserDetails, updatePreferredCountry } from '../../services/ApiServices'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { error } from 'jquery'
import { AuthError } from '@azure/msal-browser'

const PreferredLocation = () => {
    const [isLoading, setIsLoading] = useState(false)
    const currentUser = useSelector((state) => state?.MSALAuth?.currentUser?.Data)
    const [allCountry, setAllCountry] = useState([])
    const [countryId, setCountryId] = useState(currentUser?.PreferredCountryId)
    const [countryError, setCountryError] = useState(false)

    const currentUserUPN = useSelector((state) => state?.MSALAuth?.currentUser?.Data?.UPN)

    const handleSave = async () => {
        if (countryId === 0) {
            setCountryError(true)
        } else {
            setIsLoading(true)
            await updatePreferredCountry(currentUserUPN, countryId).then((response) => {
                if (response?.Success) {
                    window.location.reload()
                }
            }).catch((error) => {
                toast.error(error)
            }).finally(() => {
                setCountryError(false)
                setIsLoading(false)
            })
        }
    }

    const GetUserDetails = async () => {
        setIsLoading(true)
        await getUserDetails(currentUserUPN).then((response) => {
            if (response?.Success) {
                setCountryId(response?.Data?.PreferredCountryId)
            }
        }).catch((error) => {
            toast.error(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const GetAllCountry = async () => {
        setIsLoading(true)
        await getAllCountry().then((response) => {
            setAllCountry([...response?.Data])
        }).catch((error) => {
            toast.error(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        GetAllCountry()
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            <main id="main" className='preferred-location-main'>
                <div className='pagetitle mb'>
                    <h1>Preferred Location</h1>
                </div>
                <section className='section'>
                    <div className="row mb-4">
                        <div className='row d-flex justify-content-start'>
                            <div className='d-flex justify-content-start gap-3'>
                                <div className='col-4'>
                                    <Select
                                        value={{ value: countryId, label: countryId === 0 ? "Select Country" : allCountry?.find((c) => c?.Id === countryId)?.Name }}
                                        onChange={(e) => {
                                            if (e.value === 0) {
                                                setCountryError(true)
                                                setCountryId(Number(e.value))
                                            } else {
                                                setCountryId(Number(e.value))
                                                setCountryError(false)
                                            }
                                        }
                                        }
                                        options={[
                                            { value: 0, label: "Select Country" },
                                            ...allCountry.sort((a, b) => a.Name.localeCompare(b.Name)).map((c) => (
                                                ({ value: c?.Id, label: c?.Name })
                                            ))
                                        ]}
                                        className={`form-control p-1 `}
                                        placeholder="Select Country"
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                marginTop: 2,
                                                border: "none",
                                                boxShadow: "none",
                                            }),
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                    {countryError && <small>Please select country!</small>}
                                </div>
                                <div>
                                    <button
                                        type='button'
                                        className='add-btn btn btn-primary btn-lg '
                                        onClick={() => handleSave()}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default PreferredLocation
