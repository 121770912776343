import React, { useState, useEffect } from "react"
import NewTimeSheet from "../NewTimesheet/NewTimesheet.jsx"
import TimeSheet from "../Timesheet/Timesheet.jsx"

export default function RedirectScreen() {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 480)

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 480)
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])


    return (
        <div>
            {isMobileView ? <NewTimeSheet /> : <TimeSheet />}
        </div>
    )
}
