/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { getAllFunctions, getAllServiceCategories, getAllUsers, getApprovalTimeSheets, getProjects, getServiceDesiplines, updateApproval } from "../../services/ApiServices"
import { handleError } from "../../const"
import ReactDatePicker from "react-datepicker"
import moment from "moment"
import Loader from "../../components/Loader/Loader"
import toast from "react-hot-toast"
import swal from "sweetalert"
import "./ApprovalStatus.scss"

import downArrow from "./../../assets/img/icon-chevron-down.svg"
import { UserImage } from "../../components/UserImage/UserImage"

const ApprovalStatus = () => {
    const currentUser = useSelector((state) => state.MSALAuth.currentUser)

    const [isLoading, setIsLoading] = useState(false)
    const [isPromiseLoading, setIsPromiseLoading] = useState(false)

    const [allTimesheets, setAllTimesheets] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [allProjects, setAllProjects] = useState([])
    const [allServiceCategories, setAllServiceCategories] = useState([])
    const [allFunctions, setAllFunctions] = useState([])
    const [allServiceDisciplines, setAllServiceDisciplines] = useState([])

    const [sequenceData, setSequenceData] = useState([])

    const [calendarDate, setCalendarDate] = useState(moment().format("YYYY-MM-DD"))
    const [accordionId, setAccordionId] = useState(null) // null || number
    const [respData, setRespData] = useState([])
    const [inputSearchUser, setInputSearchUser] = useState("")

    const [allUserAndGroup, setAllUserAndGroup] = useState({ Data: [], ResultCount: 1 })
    const [inputOffset, setInputOffset] = useState(0)
    const inputPageSize = 15

    // Remove Duplicate Sequence
    const removeDupe = (timesheet) => {
        let unique = [];

        timesheet?.length > 0 && timesheet?.forEach((e) => {
            if (!unique.includes(e?.Sequence)) {
                unique.push(e?.Sequence);
            }
        });

        return unique;
    }

    // Sequence Sorting
    useEffect(() => {
        const userSeqData = allTimesheets?.map((userData) => ({
            ...userData,
            TimeSheetdata: removeDupe(userData?.TimeSheetdata)?.map((d) => ({
                Sequence: d,
                seqArray: weekDates?.map((date, dateIndex) => {
                    const defaultData = userData?.TimeSheetdata?.find((timesheet) => timesheet?.Sequence === d)
                    const data = userData?.TimeSheetdata?.find((timesheet) =>
                        timesheet?.Sequence === d &&
                        timesheet?.Date === date
                    )

                    return data ? (
                        data
                    ) : userData?.TimeSheetdata?.length > 0 ? {
                        ...defaultData,
                        Id: 0,
                        Hour: "",
                        Date: date,
                        IndexId: dateIndex,
                    } : {
                        Comment: "",
                        Date: date,
                        FunctionId: 0,
                        Hour: "",
                        Hours: "00:00",
                        Id: 0,
                        IndexId: dateIndex,
                        IsSubmited: false,
                        ProjectId: 0,
                        Sequence: 0,
                        ServiceCategoriesId: 0,
                        ServiceDisciplinesId: 0,
                        User: currentUser?.Data?.Email,
                    }
                })
            }))
        }))

        const approvalMonthSort = userSeqData.map((data) => ({
            ...data,
            ApprovalItems: data?.ApprovalItems.sort((a, b) => {
                const monthA = moment(a.MonthName, 'MMMM')
                const monthB = moment(b.MonthName, 'MMMM')

                return monthA.isBefore(monthB) ? -1 : monthA.isAfter(monthB) ? 1 : 0
            })
        }))

        setSequenceData(approvalMonthSort)
    }, [allTimesheets, currentUser?.Data?.Email])

    // Calculate user total time
    const userTotalTime = (data) => {
        let totalHours = 0;

        data?.length > 0 && data.forEach((entry) => {
            entry.seqArray.forEach(obj => {
                const hourValue = isNaN(obj.Hour) || obj.Hour === "" ? 0 : parseFloat(obj.Hour);
                totalHours += hourValue;
            });
        })
        return totalHours;
    }

    // Calculate week total time
    const weekTotalTime = (timesheet) => {
        let totalHours = 0;

        timesheet?.length > 0 && timesheet.forEach(entry => {
            if (entry.Hour !== undefined && typeof entry.Hour === 'number') {
                totalHours += entry.Hour;
            } else {
                totalHours += 0;
            }
        });

        return totalHours;
    }

    const handleDateChange = (direction) => {
        if (direction === "prev") {
            setCalendarDate((prev) => moment(prev).subtract(7, "d").format("YYYY-MM-DD"));
        } else if (direction === "next") {
            setCalendarDate((prev) => moment(prev).add(7, "d").format("YYYY-MM-DD"));
        } else {
            setCalendarDate(moment()._d);
        }
    };

    const weekDates = useMemo(() => {
        let dates = []

        const weekStart = moment(calendarDate).startOf("isoWeek").format("YYYY-MM-DD")
        Array.from(Array(7), (_, i) => i + 1)?.forEach((_, i) => {
            dates.push(moment(weekStart).add(i, "d").format("YYYY-MM-DD"))
        })

        return dates
    }, [calendarDate])

    const CustomDatePicker = forwardRef(({ value, onClick }, ref) =>
        <div className="cal-icon">
            <button type="submit" onClick={onClick} ref={ref} className="btn btn-primary-w btn-icon2 cal-btn">
                <i className="fa-regular fa-calendar" />
            </button>
        </div>
    );

    // separate months
    const currentDateInRange = () => {
        const startDateMonth = moment(weekDates[0], 'YYYY-MM-DD').format('YYYY-MM');
        const endDateMonth = moment(weekDates?.at(-1), 'YYYY-MM-DD').format('YYYY-MM');

        return { startDateMonth: startDateMonth, endDateMonth: endDateMonth }
    }

    // unlock month check and separate
    const unlockSeq = (data, month, id) => {
        const startOfMonth = month && moment(month, "MMMM").startOf('month').format('YYYY-MM-DD')
        const endOfMonth = month && moment(month, "MMMM").endOf('month').format('YYYY-MM-DD')

        const submitData = {
            "Id": id,
            "StartDate": startOfMonth,
            "EndDate": endOfMonth,
            "User": data?.User
        }

        updateApprovalTimesheet(submitData)
    }

    // Unlock Timesheet API
    const updateApprovalTimesheet = async (payload) => {
        swal({
            title: "Are you sure want to unlock timesheets?",
            text: "",
            icon: "warning",
            buttons: ['Cancel', 'Yes, unlock it!'],
            className: "custom-swal",
            successMode: true,
        }).then((willSubmit) => {
            if (willSubmit) {
                setIsLoading(true)
                updateApproval(payload).then((response) => {
                    if (response?.Success === true) {
                        toast.success("Timesheets unlocked!")
                        GetAllApprovalTimesheets()
                    }
                }).catch((error) => {
                    console.warn("error", error);
                }).finally(() => {
                    setIsLoading(false)
                })
            }
        })
    }

    useEffect(() => {
        setAllUserAndGroup({ ResultCount: sequenceData.length, Data: sequenceData.slice(0, inputPageSize) })
    }, [sequenceData])

    const HandleScrollDataLoad = () => {
        setAllUserAndGroup((previous) => ({ ResultCount: sequenceData.length, Data: [...previous?.Data, ...sequenceData.slice(allUserAndGroup?.Data.length, allUserAndGroup?.Data.length + inputPageSize)] }))
        setInputOffset(inputOffset + inputPageSize)
    }

    // Get Project, Categories, Disiplines, Teams
    useEffect(() => {
        let subscribed = true;

        setIsPromiseLoading(true)
        setAllProjects([])
        setAllServiceCategories([])
        setAllFunctions([])
        setAllServiceDisciplines([])

        Promise.all([getProjects(), getAllServiceCategories(), getServiceDesiplines(), getAllFunctions()]).then((response) => {
            if (subscribed) {
                const projectResponse = response[0]
                const serviceCategoryResponse = response[1]
                const serviceDisiplineResponse = response[2]
                const teamsResponse = response[3]

                if (typeof projectResponse?.Data === "object" && projectResponse?.Data?.length >= 0) {
                    setAllProjects(projectResponse?.Data)
                } else {
                    handleError(projectResponse)
                }

                if (typeof serviceCategoryResponse?.Data === "object" && serviceCategoryResponse?.Data?.length >= 0) {
                    setAllServiceCategories(serviceCategoryResponse?.Data)
                } else {
                    handleError(serviceCategoryResponse)
                }

                if (typeof serviceDisiplineResponse?.Data === "object" && serviceDisiplineResponse?.Data?.length >= 0) {
                    setAllServiceDisciplines(serviceDisiplineResponse?.Data)
                } else {
                    handleError(serviceDisiplineResponse)
                }

                if (typeof teamsResponse?.Data === "object" && teamsResponse?.Data?.length >= 0) {
                    setAllFunctions(teamsResponse?.Data)
                } else {
                    handleError(teamsResponse)
                }
            }
        }).catch((error) => {
            if (subscribed) {
                handleError(error)
            }
        }).finally(() => {
            if (subscribed) {
                setIsPromiseLoading(false);
            }
        })
    }, [])

    const GetAllApprovalTimesheets = async () => {
        setIsLoading(true)
        getApprovalTimeSheets(weekDates[0], weekDates?.at(-1)).then((response) => {
            if (typeof response?.Data === "object" && response?.Data?.length >= 0) {
                const timeSheetsData = response?.Data?.map((user) => ({
                    ...user,
                    TimeSheetdata: user?.TimeSheetdata?.map((timeSheet, timesheetIndex) => ({
                        ...timeSheet,
                        IndexId: timesheetIndex,
                        Date: moment(timeSheet?.Date).format("YYYY-MM-DD"),
                    }))
                }))
                // const userPAFilter = timeSheetsData?.filter((timesheet) =>
                //     currentUser?.Data?.PersonalAssistantManager && currentUser?.Data?.PersonalAssistantManager?.length > 0 &&
                //     currentUser?.Data?.PersonalAssistantManager?.find((pa) => pa?.UPN === timesheet?.User)
                // )
                setAllTimesheets(timeSheetsData)
                setRespData(response?.Data)
            } else {
                handleError(response)
            }
        }).catch((error) => {
            console.warn("error", error)
        }).finally(() => {
            setIsLoading(false)
        });
    }

    // Get All Users
    const GetAllUsers = async () => {
        setIsLoading(true);
        await getAllUsers().then((response) => {
            if (response?.Success) {
                setAllUsers(response?.Data)
            } else {
                setAllUsers([])
            }
        }).catch((error) => {
            console.warn("error", error);
        }).finally(() => {
            setIsLoading(false)
        })

        return
    }

    useEffect(() => {
        let subscribed = true;

        if (weekDates[0] && weekDates?.at(-1)) {
            setIsLoading(true)
            getApprovalTimeSheets(weekDates[0], weekDates?.at(-1)).then((response) => {
                if (subscribed) {
                    if (typeof response?.Data === "object" && response?.Data?.length >= 0) {
                        const timeSheetsData = response?.Data?.map((user) => ({
                            ...user,
                            TimeSheetdata: user?.TimeSheetdata?.map((timeSheet, timesheetIndex) => ({
                                ...timeSheet,
                                IndexId: timesheetIndex,
                                Date: moment(timeSheet?.Date).format("YYYY-MM-DD"),
                            }))
                        }))
                        setAllTimesheets(timeSheetsData)
                        setRespData(response?.Data)
                    } else {
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    console.warn("error", error)
                }
            }).finally(() => {
                setIsLoading(false)
            });
        } else {
            if (subscribed) {
                setAllTimesheets([])
            }
        }

        return () => {
            subscribed = false;
        }
    }, [weekDates[0], weekDates?.at(-1)])

    useEffect(() => {
        GetAllUsers()
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            {isPromiseLoading && <Loader />}
            {/* {isUserProfileLoading && <Loader />} */}

            <div className="approval-status">
                <main id="main" className="approval-main">
                    <div className="pagetitle mb">
                        <h1>Approve Hour</h1>
                    </div>
                    <section className="section d-flex justify-content-between">
                        {/* Calendar Header */}
                        <div className="calendar-header mb-3">
                            {/* Calendar */}
                            <div className="calendar input-group d-flex align-items-center">
                                <button
                                    type="button"
                                    onClick={() => handleDateChange("prev")}
                                    className="input-group-prepend btn btn-sm btn-icon-left"
                                    title="Previous day"
                                >
                                    <i className="fa-solid fa-chevron-left" />
                                </button>

                                <div className="px-3 py-2">
                                    <h6 className="mb-0 fw-medium text-dark text-center">
                                        {<>
                                            {moment(calendarDate).startOf("isoWeek").format("MMM DD")}
                                            {" - "}
                                            {moment(calendarDate).startOf("isoWeek").format("MMM") !== moment(calendarDate).endOf("isoWeek").format("MMM")
                                                ? moment(calendarDate).endOf("isoWeek").format("MMM DD")
                                                : moment(calendarDate).endOf("isoWeek").format("DD")
                                            }
                                            {", "}
                                            {moment(calendarDate).year()}
                                            {", Week "}
                                            {moment(calendarDate).isoWeek()}
                                        </>}
                                    </h6>
                                </div>

                                <button
                                    type="button"
                                    onClick={() => handleDateChange("next")}
                                    className="input-group-append btn btn-sm btn-icon-right"
                                    title="Next day"
                                >
                                    <i className="fa-solid fa-chevron-right" />
                                </button>
                            </div>

                            <div className="d-inline-flex gap-1">
                                {/* Calendar Btn */}
                                <ReactDatePicker
                                    selected={moment(calendarDate)._d}
                                    dateFormat="dd/MM/yyyy"
                                    // renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => (
                                    //     <div className="d-flex align-item-center justify-content-between px-3">
                                    //         <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="btn p-0" style={{ minWidth: 0 }}>
                                    //             {prevMonthButtonDisabled ? "<" : <b>{"<"}</b>}
                                    //         </button>
                                    //         <b>{moment(date).format("MMMM")}</b>
                                    //         <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="btn p-0" style={{ minWidth: 0 }}>
                                    //             {nextMonthButtonDisabled ? ">" : <b>{">"}</b>}
                                    //         </button>
                                    //     </div>
                                    // )}
                                    onChange={(date) => date && setCalendarDate(moment(date).format("YYYY-MM-DD"))}
                                    dayClassName={(date) => calendarDate && date.toDateString() === moment(calendarDate)._d.toDateString() ? "app-theme-bg-color" : ""}
                                    customInput={<CustomDatePicker />}
                                    calendarStartDay={1}
                                    todayButton="Today"
                                    className="p-1"
                                />
                            </div>
                        </div>

                        <div className="col-lg-3 text-right d-flex align-items-end">
                            <input
                                type="text"
                                className="form-control mb-3"
                                value={inputSearchUser}
                                onChange={(e) => setInputSearchUser(e.target.value)}
                                placeholder="Search user"
                            />
                        </div>
                    </section>

                    <div className="row">
                        <div className="col-lg-12">
                            {/* <InfiniteScroll
                                dataLength={allUserAndGroup}
                                next={() => HandleScrollDataLoad(inputOffset, inputPageSize, "")}
                                hasMore={inputOffset < allUserAndGroup?.ResultCount}
                                endMessage={<p className="text-center">--- <b>You have seen it all</b> ---</p>}
                            > */}
                            {sequenceData && sequenceData?.length > 0 ?
                                sequenceData?.filter((u) =>
                                    (u?.User.toLowerCase().includes(inputSearchUser?.trim().toLowerCase())) ||
                                    (u?.UserName.toLowerCase().includes(inputSearchUser?.trim().toLowerCase()))
                                )?.map((user, userIndex) =>
                                    <table key={userIndex} className="userTable table tableCustom table-hover valignM tableUsers tableHours">
                                        <tbody>
                                            <tr className="userHeader">
                                                <td className="header-arrow-btn">
                                                    <button
                                                        className={`btn btn-primary-w btn-icon-gray d-flex justify-content-center align-items-center p-0 me-2 shadow0 btnToggle${accordionId === userIndex ? " btn-border" : ""}`}
                                                        onClick={() => setAccordionId((prev) => prev !== userIndex ? userIndex : null)}
                                                        type="text"
                                                    >
                                                        <img src={downArrow} alt="down-arrow" />
                                                    </button>
                                                </td>
                                                <td className="txt-medium" colSpan="6" onClick={() => setAccordionId((prev) => prev !== userIndex ? userIndex : null)}>
                                                    <div className="nav-profile d-flex align-items-center pe-0">
                                                        <UserImage className="rounded-circle profile-width me-3" email={user?.User} size={40} border="none" />
                                                        <span>{allUsers && allUsers?.length > 0 && allUsers?.find((u) => u?.Email === user?.User)?.Name}</span>
                                                    </div>
                                                </td>
                                                <td className="text-end pe-3" onClick={() => setAccordionId((prev) => prev !== userIndex ? userIndex : null)}>
                                                    <span>{userTotalTime(user?.TimeSheetdata).toFixed(2)}</span>{" "}<span className="txt-gray">Hrs.</span>
                                                </td>
                                                {user?.ApprovalItems && user?.ApprovalItems.length > 0 &&
                                                    user?.ApprovalItems.map((month, monthIndex) =>
                                                        <td key={monthIndex} align="right" className="" style={{ width: "100px" }}>
                                                            <button
                                                                type="submit"
                                                                className="btn lock-btn"
                                                                onClick={() => unlockSeq(user, month?.MonthName, month?.Id)}
                                                            >
                                                                Unlock {`(${month?.MonthName})`}
                                                            </button>
                                                        </td>
                                                    )}
                                            </tr>
                                            <tr className={`listing table-row${accordionId === userIndex ? "" : " d-none"}`}>
                                                <td colSpan="10" className="p-0">
                                                    <div className="divApproval">
                                                        <table className="table tableCustom valignM tableHours">
                                                            <thead className="table-date-header">
                                                                <tr className="header-row" align="center">
                                                                    <th scope="col" colSpan="7" align="left">Projects</th>
                                                                    {weekDates && weekDates?.length > 0 &&
                                                                        weekDates?.map((date, dateIndex) =>
                                                                            <th key={dateIndex} scope="col">
                                                                                <span className="week">{moment(date).format("ddd")}</span>
                                                                                <span className="day">{moment(date).format("MMM DD")}</span>
                                                                            </th>
                                                                        )}
                                                                    <th scope="col" colSpan="6">&nbsp;</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="table-date-body">
                                                                {user?.TimeSheetdata?.length > 0 && user?.TimeSheetdata?.map((seq, seqIndex) =>
                                                                    seq?.seqArray[0]?.IsSubmited === true &&
                                                                    <tr key={seqIndex} align="center" className="mb-2 seq-row">
                                                                        <td className="body-project-col" colSpan="7">
                                                                            <div className="project-div d-flex align-items-center text-start px-3">
                                                                                <div className="ms-2">
                                                                                    <div className="project-display fw-medium p-0">
                                                                                        {allServiceCategories && allServiceCategories?.length > 0
                                                                                            ? allServiceCategories?.filter((cate) => cate?.Id === seq?.seqArray[0]?.ServiceCategoriesId)[0]?.ServiceCategoriesName
                                                                                            ?? "--"
                                                                                            : "--"
                                                                                        }{" - "}
                                                                                        {allServiceDisciplines && allServiceDisciplines?.length > 0
                                                                                            ? allServiceDisciplines?.filter((disc) => disc?.Id === seq?.seqArray[0]?.ServiceDisciplinesId)[0]?.ServiceDisciplinesName
                                                                                            ?? "--"
                                                                                            : "--"
                                                                                        }
                                                                                    </div>
                                                                                    <div className="comments-display text-black-50 p-0">
                                                                                        {allProjects && allProjects?.length > 0
                                                                                            ? allProjects?.filter((pro) => pro?.Id === seq?.seqArray[0]?.ProjectId)[0]?.ProjectName
                                                                                            ?? "--"
                                                                                            : "--"
                                                                                        }{" - "}
                                                                                        {allFunctions && allFunctions?.length > 0
                                                                                            ? allFunctions?.filter((func) => func?.Id === seq?.seqArray[0]?.FunctionId)[0]?.Name
                                                                                            ?? "--"
                                                                                            : "--"
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        {seq?.seqArray && seq?.seqArray?.length > 0 &&
                                                                            seq?.seqArray?.map((timesheet, tIndex) =>
                                                                                <td key={tIndex}>
                                                                                    <span className="week">1</span>
                                                                                    <input type="text" className="form-control form2" value={timesheet?.Hour} disabled />
                                                                                </td>
                                                                            )}
                                                                        <td>&nbsp;</td>
                                                                        <td colSpan="3"><span>{weekTotalTime(seq?.seqArray).toFixed(2)}</span>&nbsp;&nbsp;<span className="txt-gray">Hrs.</span></td>
                                                                        <td>&nbsp;</td>
                                                                        {/* <td align="right">
                                                                            <button type="submit" className="btn lock-btn" onClick={() => unlockSeq([seq])}>Unlock</button>
                                                                        </td> */}
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )
                                : <div className="mt-4">
                                    <div className="d-flex justify-content-center fs-3">No Data</div>
                                </div>
                            }
                            {/* </InfiniteScroll> */}
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default ApprovalStatus