import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    AllUsersImages: {}
};

export const APIRespSlice = createSlice({
    name: "APIResponse",
    initialState,
    reducers: {
        changeAllUsersImage: (state, action) => {
            if (typeof action.payload === "function") {
                state.AllUsersImages = (action.payload)(state.AllUsersImages)
            } else {
                state.AllUsersImages = action.payload
            }
        },
    },
});

export const { changeAllUsersImage } = APIRespSlice.actions;

export default APIRespSlice.reducer;