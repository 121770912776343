import { configureStore } from "@reduxjs/toolkit"
import MSALSlice from "./Slices/MSALSlice"
import APIRespSlice from "./Slices/APIRespSlice"

export const store = configureStore({
  reducer: {
    MSALAuth: MSALSlice,
    APIResponse: APIRespSlice
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})