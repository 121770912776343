import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader/Loader';
import Select from 'react-select'
import './Holidays.scss'
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { createUpdateHoliday, deleteHoliday, getAllCountry, getAllHoliday } from '../../services/ApiServices';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import swal from 'sweetalert'

import editIcon from "../../assets/img/icon-edit.svg"

const Holidays = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [allCountry, setAllCountry] = useState([])
    const [allHolidays, setAllHolidays] = useState([])

    // ===============Add modal states & Validation states ==========================
    const [addHolidayInput, setAddHolidayInput] = useState({ name: "", country: 0, date: moment()._d })
    const [nameError, setNameError] = useState(false)
    const [countryError, setCountryError] = useState(false)
    const [dateError, setDateError] = useState(false)

    // ===============Edit modal states && Validation states ==========================
    const [editHolidayInput, setEditHolidayInput] = useState({ Id: 0, name: "", country: 0, date: null, IsActive: false })
    const [editNameError, setEditNameError] = useState(false)
    const [editCountryError, setEditCountryError] = useState(false)
    const [editDateError, setEditDateError] = useState(false)

    const [inputFilterCountry, setinputFilterCountry] = useState("")
    const [inputFilterYear, setInputFilterYear] = useState(moment().format("YYYY"))

    const clearStates = () => {
        setAddHolidayInput({ name: "", country: 0, date: moment()._d })
        setEditHolidayInput({ Id: 0, name: "", country: 0, date: null, IsActive: false })
        setNameError(false)
        setCountryError(false)
        setDateError(false)
        setEditNameError(false)
        setEditCountryError(false)
        setEditDateError(false)
    }

    const validations = (flag) => {
        if (flag === "add") {
            if (addHolidayInput?.name.trim().length === 0) {
                setNameError(true)
            } else if (addHolidayInput?.country === 0) {
                setCountryError(true)
            } else if (!addHolidayInput?.date) {
                setDateError(true)
            } else {
                setNameError(false)
                setCountryError(false)
                setDateError(false)
                CreateUpdateHoliday("add")
            }
        } else {
            if (editHolidayInput?.name.trim().length === 0) {
                setEditNameError(true)
            } else if (editHolidayInput?.country === 0) {
                setEditCountryError(true)
            } else if (!editHolidayInput?.date) {
                setEditDateError(true)
            } else {
                setEditNameError(false)
                setEditCountryError(false)
                setEditDateError(false)
                CreateUpdateHoliday()
            }
        }
    }

    const onClickDeleteHoliday = (id) => {
        const deleteData = async () => {
            setIsLoading(true);

            await deleteHoliday(id).then((response) => {
                if (response?.Success) {
                    toast.success(response?.Message)
                } else {
                    toast.error(response?.Message)
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
                GetAllHolidays();
            })
        }

        swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover this Holiday!',
            icon: 'warning',
            buttons: ['Cancel', 'Yes, delete it!'],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteData();
            } else {
                setIsLoading(false);
            }
        });
    }

    const GetAllHolidays = async () => {
        setIsLoading(true)
        await getAllHoliday().then((response) => {
            if (response?.Success) {
                setAllHolidays([...response?.Data])
            }
        }).catch((error) => {
            console.warn(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const CreateUpdateHoliday = async (flag, data) => {
        let payload;
        if (flag === "add") {
            payload = {
                Name: addHolidayInput?.name,
                CountryId: Number(addHolidayInput?.country),
                Date: addHolidayInput?.date,
            }
        } else if (flag === "status") {
            payload = {
                Id: data?.Id,
                Name: data?.Name,
                CountryId: allCountry?.find((d) => d?.Name === data?.Country)?.Id,
                Date: moment(data?.date).format("YYYY-MM-DDTHH:mm:ss"),
                IsActive: !data?.IsActive
            }
        } else {
            payload = {
                Id: editHolidayInput?.Id,
                Name: editHolidayInput?.name,
                CountryId: allCountry?.find((d) => d?.Id === editHolidayInput?.country)?.Id,
                Date: moment(editHolidayInput?.date).format("YYYY-MM-DDTHH:mm:ss"),
                IsActive: editHolidayInput?.IsActive
            }
        }

        setIsLoading(true)
        await createUpdateHoliday(payload).then((response) => {
            if (response?.Success) {
                toast.success(response?.Message)
                GetAllHolidays()
            }
        }).catch((error) => {
            console.warn(error)
        }).finally(() => {
            setAddHolidayInput({ name: "", country: 0, date: null })
            setIsLoading(false)
        })
    }

    const GetAllCountry = async () => {
        setIsLoading(true)
        await getAllCountry().then((response) => {
            setAllCountry([...response?.Data])
        }).catch((error) => {
            console.warn(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        GetAllCountry()
        GetAllHolidays()
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            <main id="main" className='holidays-main'>

                {/* =================== Title ============================================ */}
                <div className='pagetitle'>
                    <h1>Holidays</h1>
                </div>

                <section className="section">
                    {/* =============== inputs and calendar year ============================================== */}
                    <div className="d-flex justify-content-between mb-4">
                        <div className='d-flex justify-content-center gap-2'>
                            <button
                                type='button'
                                className='add-btn btn btn-primary btn-lg'
                                data-bs-toggle="modal"
                                data-bs-target="#addholiday"
                            >
                                Add
                            </button>
                            <Link
                                to="/import-holidays"
                                target='_blank'
                                className='text-reset'
                            >
                                <button
                                    type='button'
                                    className='add-btn btn btn-primary btn-lg '
                                >
                                    Import
                                </button>
                            </Link>
                        </div>
                        <div className='d-flex justify-content-center gap-2'>
                            <div className='col'>
                                <ReactDatePicker
                                    dateFormat="yyyy"
                                    showYearPicker
                                    selected={inputFilterYear}
                                    onChange={(date) => date && setInputFilterYear(moment(date).format("YYYY"))}
                                    className='form-control'
                                    placeholderText='Select year'
                                />
                            </div>
                            <div className='col'>
                                <ReactSelect
                                    value={{
                                        value: inputFilterCountry,
                                        label: inputFilterCountry === "" ? "Select Country" : allCountry?.find((c) => c?.Name === inputFilterCountry)?.Name
                                    }}
                                    onChange={(e) => setinputFilterCountry(e.value)}
                                    options={[
                                        { value: "", label: "Select Country" },
                                        ...allCountry.sort((a, b) => a.Name.localeCompare(b.Name)).map((c) => (
                                            ({ value: c?.Name, label: c?.Name })
                                        ))
                                    ]}
                                    className="form-control p-1"
                                    placeholder="Select Country"
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            marginTop: 2,
                                            border: "none",
                                            boxShadow: "none",
                                        }),
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* =================================== table ========================================= */}
                    <table className='table tableCustom table-hover'>
                        <thead>
                            <tr className='row mb-1'>
                                <th className='col-4' scope='col'>Name</th>
                                <th className='col' scope='col'>Country</th>
                                <th className='col' scope='col'>Date</th>
                                <th className='col text-end' scope='col'>Actions</th>
                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {allHolidays && allHolidays?.length > 0 &&
                                allHolidays?.filter((holiday) =>
                                    (inputFilterCountry ? holiday?.Country === inputFilterCountry : true) &&
                                    (inputFilterYear ? moment(holiday?.Date).format("YYYY") === inputFilterYear : true)
                                )?.map((h) => (
                                    <tr key={h?.Id} className='row'>
                                        <td className='col-4 txt-medium'>{h?.Name}</td>
                                        <td className="col">{h?.Country}</td>
                                        <td className="col">{moment(h?.Date).format("YYYY-MM-DD")}</td>
                                        <td className='col buttons' align='right'>
                                            <button
                                                type='button'
                                                data-bs-toggle="modal"
                                                data-bs-target="#editHoliday"
                                                className='edit-btn btn p-0 btn-primary-w btn-icon me-2'
                                                onClick={() => {
                                                    setEditHolidayInput({
                                                        name: h?.Name,
                                                        country: allCountry?.find((d) => d?.Name === h?.Country)?.Id,
                                                        Id: h?.Id,
                                                        date: moment(h?.Date).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"),
                                                        IsActive: h?.IsActive
                                                    });
                                                }}
                                            >
                                                <i className="fa-solid fa-pen p-2" />
                                            </button>
                                            <button type='button' className='delete-btn btn p-0 btn-primary-w btn-icon me-2' onClick={() => onClickDeleteHoliday(h?.Id)}>
                                                <i className="fa-solid fa-trash-can p-2" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </section>

                {/* =================== Add modal =========================== */}
                <div className="modal fade" id="addholiday" tabIndex={-1}>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Add Holidays</h5>
                                <button
                                    type='button'
                                    className='btn-close'
                                    aria-label='Close'
                                    data-bs-dismiss="modal"
                                    onClick={clearStates}
                                />
                            </div>
                            <div className='modal-body mb-4'>
                                <form className='row g-3'>
                                    <div className='col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Name</label>
                                        <input
                                            type='text'
                                            className={`form-control ${nameError ? "mb-0" : "mb-3"}`}
                                            id='inputName4'
                                            value={addHolidayInput?.name}
                                            onChange={e => {
                                                setAddHolidayInput({ ...addHolidayInput, name: e.target.value })
                                            }}
                                            placeholder='Please enter name'
                                            autocomplete="off"
                                        />
                                        {nameError && <p className='fs-6 text-danger ps-2'>Please enter name!</p>}
                                    </div>
                                    <div className='col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Country</label>
                                        <Select
                                            value={{ value: addHolidayInput?.country, label: addHolidayInput?.country === 0 ? "Select country" : allCountry?.find((c) => c?.Id === addHolidayInput?.country)?.Name }}
                                            onChange={(e) => setAddHolidayInput({ ...addHolidayInput, country: Number(e.value) })}
                                            options={[{ value: 0, label: "Select Country" }, ...allCountry.map((c) => (
                                                ({ value: c?.Id, label: c?.Name })
                                            ))]}
                                            className={`form-control p-1 `}
                                            placeholder="Select Country"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                        {countryError && <p className='fs-6 text-danger ps-2'>Please select country!</p>}
                                    </div>
                                    <div className='col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Date</label>
                                        <ReactDatePicker
                                            selected={addHolidayInput?.date ? new Date(addHolidayInput?.date) : null}
                                            dateFormat={'yyyy-MM-dd'}
                                            onChange={(date) => setAddHolidayInput({ ...addHolidayInput, date: date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : null })}
                                            placeholderText='Select Date'
                                            className={`form-control ${dateError ? "mb-0" : "mb-3"}`}
                                            style={{ display: "inline-flex" }}
                                            calendarStartDay={1}
                                        />
                                        {dateError && <p className='fs-6 text-danger ps-2'>Please select date!</p>}
                                    </div>
                                </form>
                            </div>
                            <div className='modal-footer'>
                                <button
                                    type='button'
                                    data-bs-dismiss="modal"
                                    className='cancel-btn btn btn-transparent btn-lg m-0 me-2'
                                    onClick={clearStates}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='button'
                                    className='submit-btn btn btn-primary btn-lg m-0'
                                    onClick={() => validations("add")}
                                    data-bs-dismiss={addHolidayInput?.name.trim().length > 0 && addHolidayInput?.country !== 0 && addHolidayInput?.date ? "modal" : ""}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div >

                {/* =================== Edit modal =========================== */}
                <div className="modal fade" id="editHoliday" tabIndex={-1}>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Edit Holiday</h5>
                                <button
                                    type='button'
                                    className='btn-close'
                                    aria-label='Close'
                                    data-bs-dismiss="modal"
                                    onClick={clearStates}
                                />
                            </div>
                            <div className='modal-body mb-4'>
                                <form className='row g-3'>
                                    <div className='col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Name</label>
                                        <input
                                            type='text'
                                            className={`form-control`}
                                            id='inputName4'
                                            value={editHolidayInput?.name}
                                            onChange={e => {
                                                setEditHolidayInput({ ...editHolidayInput, name: e.target.value })
                                            }}
                                            placeholder='Please enter name'
                                        />
                                        {editNameError && <p className='fs-6 text-danger ps-2'>Please enter name!</p>}
                                    </div>
                                    <div className='col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Country</label>
                                        <Select
                                            value={{ value: editHolidayInput?.country, label: allCountry?.find((d) => d?.Id === editHolidayInput?.country)?.Name }}
                                            onChange={(e) => setEditHolidayInput({ ...editHolidayInput, country: Number(e.value) })}
                                            options={allCountry?.map((c) => (
                                                ({ value: c?.Id, label: c?.Name })
                                            ))}
                                            className={`form-control p-1 `}
                                            placeholder="Select Country"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                        {editCountryError && <p className='fs-6 text-danger ps-2'>Please select country!</p>}
                                    </div>
                                    <div className='col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Date</label>
                                        <ReactDatePicker
                                            selected={editHolidayInput?.date ? moment(editHolidayInput?.date)._d : null}
                                            dateFormat={'yyyy-MM-dd'}
                                            onChange={(date) => setEditHolidayInput({ ...editHolidayInput, date: date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : null })}
                                            placeholderText='Select Date'
                                            className='form-control '
                                            style={{ display: "inline-flex" }}
                                            calendarStartDay={1}
                                        />
                                        {editDateError && <p className='fs-6 text-danger ps-2'>Please select date!</p>}
                                    </div>
                                    {/* <div className="col-6 mb-3 m-0 d-flex align-items-center">
                                        <label className="form-label me-2 m-0" htmlFor="modal-project">Status :</label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="modal-project"
                                            checked={editHolidayInput?.IsActive}
                                            onChange={(e) => setEditHolidayInput({ ...editHolidayInput, IsActive: !editHolidayInput?.IsActive })}
                                            style={{ height: "1.5rem", width: "1.5rem" }}
                                        />
                                    </div> */}
                                </form>
                            </div>
                            <div className='modal-footer'>
                                <button
                                    type='button'
                                    data-bs-dismiss="modal"
                                    className='cancel-btn btn btn-transparent btn-lg m-0 me-2'
                                    onClick={clearStates}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='button'
                                    data-bs-dismiss={editHolidayInput?.name.trim().length > 0 && editHolidayInput?.country !== 0 && editHolidayInput?.date ? "modal" : ""}
                                    className='submit-btn btn btn-primary btn-lg m-0'
                                    onClick={() => validations()}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </main>
        </>
    )
}

export default Holidays
