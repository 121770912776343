import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'
import './Projects.scss'
import { addUpdateProject, deleteProject, getAzureADUsers, getProjects } from '../../services/ApiServices'
import Loader from '../../components/Loader/Loader'
import toast from 'react-hot-toast'
import Select from 'react-select'

const Projects = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isCreateUpdateLoading, setIsCreateUpdateLoading] = useState(false)
    const [isProjectAddLoading, setIsProjectAddLoading] = useState(false)

    const [allProject, setAllProject] = useState([]);
    const [filterAllProject, setFilterAllProject] = useState([]);
    const [title, setTitle] = useState('');
    const [showError, setShowError] = useState(false)
    const [showUpdateError, setShowUpdateError] = useState(false)

    // for Add modal 
    const [modalProjectName, setModalProjectName] = useState("")
    const [modalId, setModalId] = useState("")
    const [modalFund, setModalFund] = useState("")
    const [modalCountry, setModalCountry] = useState("")
    const [modalRegion, setModalRegion] = useState("")
    const [modalProjectOwners, setModalProjectOwners] = useState([])
    const [queryStringDM, setQueryStringDM] = useState("")
    const [allDMUsers, setAllDMUsers] = useState([])

    //for edit modal
    const [editedProject, setEditedProject] = useState({ Id: 0, IsActive: "", ProjectName: false });
    const [editId, setEditId] = useState("")
    const [editFund, setEditFund] = useState('')
    const [editCountry, setEditCountry] = useState('')
    const [editRegion, setEditRegion] = useState('')
    const [editProjectOwners, setEditProjectOwners] = useState([])
    const [editQuery, setEditQuery] = useState("")
    const [editAllOwners, setEditAllOwners] = useState([])

    // Function to handle editing of a project
    const editProject = (data) => {
        setEditedProject({ Id: data?.Id, ProjectName: data?.ProjectName, IsActive: data?.IsActive });
        setEditId(data?.ProjectId ?? "")
        setEditFund(data?.Fund ?? "")
        setEditCountry(data?.Country ?? "")
        setEditRegion(data?.Region ?? "")
        setEditProjectOwners([...data?.OwnersList])
    };

    const updateProjectApiCall = async (data, flag) => {
        let payload = {}
        if (flag) {
            payload = {
                Id: data?.Id,
                ProjectName: data?.ProjectName,
                IsActive: flag === "status" ? !data?.IsActive : data?.IsActive,
                Fund: data?.Fund,
                Country: data?.Country,
                Region: data?.Region,
                ProjectOwners: data?.OwnersList,
                ProjectId: data?.ProjectId
            }
        } else {
            payload = {
                Id: data?.Id,
                ProjectName: data?.ProjectName,
                IsActive: data?.IsActive,
                Fund: editFund,
                Country: editCountry,
                Region: editRegion,
                ProjectOwners: editProjectOwners,
                ProjectId: editId
            }
        }

        setIsCreateUpdateLoading(true)
        await addUpdateProject(payload).then((response) => {
            if (response?.Success === true) {
                toast.success(response?.Message)
                GetAllProjects()
            } else {
                toast.error(response?.Message)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsCreateUpdateLoading(false)
        })
    }

    const filterByStatus = (val) => {
        if (val === 0) {
            setFilterAllProject([...allProject])
        } else {
            setFilterAllProject(allProject.filter((v) => v.IsActive === val))
        }
    }

    // Add project Data
    const addProjectData = async () => {
        if (modalProjectName.trim().length > 0) {
            setIsProjectAddLoading(true);
            setShowError(false)

            const payload = {
                ProjectName: modalProjectName,
                Fund: modalFund,
                Country: modalCountry,
                Region: modalRegion,
                ProjectOwners: modalProjectOwners,
                ProjectId: modalId
            }

            await addUpdateProject(payload).then((response) => {
                if (response?.Success === true) {
                    toast.success(response?.Message)
                    GetAllProjects()
                }
                else {
                    toast.error(response?.Message)
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setModalProjectName('')
                setModalCountry('')
                setModalId("")
                setModalFund('')
                setModalRegion('')
                setModalProjectOwners([])
                setIsProjectAddLoading(false)
            })
        }
        else {
            setShowError(true)
        }
    };

    // Delete project Data
    const deleteProjects = (Id) => {
        const deleteData = async () => {
            setIsLoading(true);

            await deleteProject(Id).then((response) => {
                toast.success(response?.Message)
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
                GetAllProjects();
            })
        };

        swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover this project!',
            icon: 'warning',
            buttons: ['Cancel', 'Yes, delete it!'],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteData();
            } else {
                setIsLoading(false);
            }
        });
    };

    // Get All Projects
    const GetAllProjects = async () => {
        setIsLoading(true);

        await getProjects().then((response) => {
            if (response?.Success) {
                setAllProject(response?.Data)
                setFilterAllProject(response?.Data)
            } else {
                setAllProject([])
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        GetAllProjects()
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (queryStringDM.length >= 3) {
                getAzureADUsers(queryStringDM).then((response) => {
                    if (response?.Data) {
                        const existingUsersSet = new Set(allDMUsers.map(user => user.UPN));

                        const newUsers = response?.Data.filter(dm => !existingUsersSet.has(dm.UPN))
                            .map(dm => ({ Name: dm.Name, UPN: dm.UPN, Id: dm.UserID }));

                        setAllDMUsers(prevUsers => [...prevUsers, ...newUsers]);
                    }
                }).catch((error) => {
                    console.warn("error", error)
                })
            }
        }, 1000);

        return () => clearTimeout(timeout);
    }, [queryStringDM])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (editQuery.length >= 3) {
                getAzureADUsers(editQuery).then((response) => {
                    if (response?.Data) {
                        const existingUsersSet = new Set(editAllOwners.map(user => user.UPN));

                        const newUsers = response?.Data.filter(dm => !existingUsersSet.has(dm.UPN))
                            .map(dm => ({ Name: dm.Name, UPN: dm.UPN, Id: dm.UserID }));

                        setEditAllOwners(prevUsers => [...prevUsers, ...newUsers]);
                    }
                }).catch((error) => {
                    console.warn("error", error)
                })
            }
        }, 1000);

        return () => clearTimeout(timeout);
    }, [editQuery])

    return (
        <>
            {isLoading && <Loader />}
            {isCreateUpdateLoading && <Loader />}
            {isProjectAddLoading && <Loader />}

            <main id='main' className='project-main'>

                <section className='section'>
                    {/* Header Section */}
                    <div className='add-section d-flex justify-content-between mb'>
                        <div className='pagetitle col'>
                            <h1>Projects</h1>
                        </div>
                        <div className='col d-flex justify-content-end align-items-center mb-2'>
                            <div className='mt-3'>
                                <label
                                    className={`txt-medium me-2 mb-3`}
                                    htmlFor="project"
                                >
                                    Status:
                                </label>
                            </div>
                            <div className='col-5 '>
                                <Select
                                    defaultValue={0}
                                    onChange={(e) => filterByStatus(e.value)}
                                    options={[
                                        { value: 0, label: "Select status" },
                                        ...[true, false].map((v) => (
                                            ({ value: v, label: v ? "Active" : "Inactive" })
                                        ))
                                    ]}
                                    className={`form-control p-1 mb-0`}
                                    placeholder="Filter by status"
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            marginTop: 2,
                                            border: "none",
                                            boxShadow: "none",
                                        }),
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Table */}
                    <table className='table tableCustom table-hover'>
                        <thead className='w-100'>
                            <tr className='row'>
                                <th className='col-3' scope='col'>Id</th>
                                <th className='col-3' scope='col'>Title</th>
                                <th className='col-3' scope='col'>Status</th>
                                <th className='col-3 text-end' scope='col'>Actions</th>
                            </tr>
                        </thead>
                        <tbody className='w-100'>
                            {filterAllProject?.map((project, Id) => (
                                <tr key={Id} className='row project-row'>
                                    <td className='col-3 txt-medium'>{project?.ProjectId !== null && project?.ProjectId.length !== 0 ? project?.ProjectId : "--"}</td>
                                    <td className='col-3 txt-medium'>{project?.ProjectName ?? "--"}</td>
                                    <td className='col-3 col-status'>
                                        <button
                                            className={`btn active-btn ${project?.IsActive ? "btn-outline-success" : "btn-outline-danger"}`}
                                            onClick={() => {
                                                editProject(project)
                                                updateProjectApiCall(project, "status")
                                            }}
                                        >
                                            {project?.IsActive ? "Active" : "Inactive"}
                                        </button>
                                    </td>
                                    <td className='col-3 buttons' align='right'>
                                        <button
                                            type='button'
                                            data-bs-toggle="modal"
                                            data-bs-target="#editmodal"
                                            className='edit-btn btn btn-primary-w btn-icon me-2'
                                            onClick={() => {
                                                editProject(project)
                                            }}
                                        >
                                            <i className="fa-solid fa-pen p-2" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Add button  */}
                    <div className='add-section d-flex justify-items-end mb'>
                        <div className=' col  '>
                            <button type='button'
                                className='add-btn btn btn-primary d-flex justify-content-center align-self-end btn-lg'
                                data-bs-toggle="modal"
                                data-bs-target="#addmodal"
                                style={{ marginLeft: "auto" }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </section>

                {/* Modal */}
                <div className="modal fade" id='editmodal' tabIndex={-1}>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Edit Project</h5>
                                <button type='button' className='btn-close' aria-label='Close'
                                    data-bs-dismiss="modal" />
                            </div>
                            <div className='modal-body mb-3'>
                                <form className='row g-3'>
                                    <div className='name-div col-12 mb-1'>
                                        <label htmlFor='ProjectName' className='form-label'>Project Name</label>
                                        <input
                                            type='text'
                                            className={`form-control ${showUpdateError ? "mb-0" : "mb-3"}`}
                                            id='ProjectName'
                                            value={editedProject?.ProjectName}
                                            onChange={e => {
                                                setEditedProject({ ...editedProject, ProjectName: e.target.value })
                                                setShowUpdateError(false)
                                            }}
                                            placeholder='Please enter title'
                                        />
                                        {!editedProject?.ProjectName && showUpdateError && <p className='fs-6 text-danger ps-2'>Please enter Project Name!</p>}
                                    </div>
                                    <div className="id-div col-12 mb-3 m-0">
                                        <label htmlFor="Id" className="form-label">Id</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Id"
                                            placeholder='Enter Id'
                                            onChange={(e) => setEditId(e.target.value)}
                                            value={editId}
                                        />
                                    </div>
                                    <div className="fund-div col-12 mb-3 m-0">
                                        <label htmlFor="Fund" className="form-label">Fund</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Fund"
                                            placeholder='Enter Fund'
                                            onChange={(e) => setEditFund(e.target.value)}
                                            value={editFund}
                                        />
                                    </div>
                                    <div className="country-div col-12 mb-3 m-0">
                                        <label htmlFor="Country" className="form-label">Country</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Country"
                                            placeholder='Enter country'
                                            onChange={(e) => setEditCountry(e.target.value)}
                                            value={editCountry}
                                        />
                                    </div>
                                    <div className="region-div col-12 mb-3 m-0">
                                        <label htmlFor="Region" className="form-label">Region</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Region"
                                            placeholder='Enter Region'
                                            onChange={(e) => setEditRegion(e.target.value)}
                                            value={editRegion}
                                        />
                                    </div>
                                    <div className="owner-div col-12 mb-2 m-0">
                                        <label htmlFor="ProjectOwner" className="form-label">Project Owner</label>
                                        <Select
                                            value={editProjectOwners?.length > 0 ?
                                                (editProjectOwners?.map((dmUser) => ({

                                                    label: dmUser?.OwnerName,
                                                    value: dmUser?.OwnerUPN
                                                })))
                                                : []}
                                            isMulti
                                            id='ProjectOwner'
                                            inputValue={editQuery}
                                            onInputChange={(e) => setEditQuery(e)}
                                            onChange={(selectedOptions) => {
                                                const updatedProjectOwners = selectedOptions.map((d) => {
                                                    return {
                                                        OwnerName: d?.label,
                                                        OwnerUPN: d?.value
                                                    }
                                                });
                                                setEditProjectOwners(updatedProjectOwners);
                                            }}
                                            placeholder={<span className='fw-100 px-2 opacity-75' >Search and select Project owner</span>}
                                            options={editAllOwners?.length > 0
                                                ? [...editAllOwners?.map((users) => ({
                                                    label: users?.Name,
                                                    value: users?.UPN,
                                                }))]
                                                : []
                                            }
                                            components={{
                                                IndicatorSeparator: () => null,
                                                DropdownIndicator: () => null,
                                            }}
                                        />
                                    </div>
                                    <div className="status-div col-6 mb-2 m-0 d-flex align-items-center mt-2">
                                        <label className="form-label me-2 m-0" htmlFor="modal-project">Status :</label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="modal-project"
                                            checked={editedProject?.IsActive}
                                            onChange={(e) => setEditedProject({ ...editedProject, IsActive: !editedProject?.IsActive })}
                                            style={{ height: "1.5rem", width: "1.5rem" }}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className='modal-footer'>
                                <button
                                    type='button'
                                    className='cancel-btn btn btn-transparent btn-lg m-0 me-2'
                                    data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='button'
                                    data-bs-dismiss={editedProject?.ProjectName ? "modal" : ""}
                                    className='submit-btn btn btn-primary btn-lg m-0'
                                    onClick={() => {
                                        if (editedProject?.ProjectName) {
                                            setShowUpdateError(false)
                                            updateProjectApiCall(editedProject)
                                        }
                                        else {
                                            setShowUpdateError(true)
                                        }
                                    }}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add modal  */}
                < div className="modal fade" id='addmodal' >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Project</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" />
                            </div>
                            <div className="modal-body mb-4">
                                <form className="row g-3">
                                    <div className="col-12 mb-3 m-0">
                                        <label htmlFor="inputName4" className="form-label">Project Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputName4"
                                            placeholder='Enter Project name'
                                            onChange={(e) => setModalProjectName(e.target.value)}
                                            value={modalProjectName}
                                        />
                                        {showError && <small className='text-danger'>Please enter Project name!</small>}
                                    </div>
                                    <div className="col-12 mb-3 m-0">
                                        <label htmlFor="inputName4" className="form-label">Id</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputName4"
                                            placeholder='Enter Id'
                                            onChange={(e) => setModalId(e.target.value)}
                                            value={modalId}
                                        />
                                    </div>
                                    <div className="col-12 mb-3 m-0">
                                        <label htmlFor="inputName4" className="form-label">Fund</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputName4"
                                            placeholder='Enter Fund'
                                            onChange={(e) => setModalFund(e.target.value)}
                                            value={modalFund}
                                        />
                                    </div>
                                    <div className="col-12 mb-3 m-0">
                                        <label htmlFor="inputName4" className="form-label">Country</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputName4"
                                            placeholder='Enter country'
                                            onChange={(e) => setModalCountry(e.target.value)}
                                            value={modalCountry}
                                        />
                                    </div>
                                    <div className="col-12 mb-3 m-0">
                                        <label htmlFor="inputName4" className="form-label">Region</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputName4"
                                            placeholder='Enter Region'
                                            onChange={(e) => setModalRegion(e.target.value)}
                                            value={modalRegion}
                                        />
                                    </div>
                                    <div className="col-12 mb-2 m-0">
                                        <label htmlFor="inputName4" className="form-label">Project Owner</label>
                                        <Select
                                            value={modalProjectOwners?.length > 0 ? (
                                                modalProjectOwners?.map((dmUser) => ({
                                                    label: dmUser?.OwnerName,
                                                    value: dmUser?.OwnerUPN
                                                }))
                                            ) : []}
                                            isMulti
                                            inputValue={queryStringDM}
                                            onInputChange={(e) => setQueryStringDM(e)}
                                            onChange={(selectedOptions) => {
                                                const updatedProjectOwners = selectedOptions.map((d) => {
                                                    return {
                                                        OwnerName: d?.label,
                                                        OwnerUPN: d?.value
                                                    }
                                                });
                                                setModalProjectOwners(updatedProjectOwners);
                                            }}
                                            placeholder={<span className='fw-100 px-2 opacity-75'>Search to select Project owner</span>}
                                            options={allDMUsers?.length > 0
                                                ? [...allDMUsers?.map((users) => ({
                                                    label: users?.Name,
                                                    value: users?.UPN,
                                                }))]
                                                : []
                                            }
                                            components={{
                                                IndicatorSeparator: () => null,
                                                DropdownIndicator: () => null,
                                            }}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    className="cancel-btn btn btn-transparent btn-lg m-0 me-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    data-bs-dismiss={`${modalProjectName?.trim()?.length > 0 ? "modal" : ""}`}
                                    className="submit-btn btn btn-primary btn-lg m-0"
                                    onClick={() => addProjectData()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </main>
        </>
    );
};

export default Projects;



