import React, { useEffect, useState } from "react"
import { changeAccessToken, changeAuthenticated, changeUserName, changeUserEmail, changeUserImage, changeCurrentUser } from "./Store/Slices/MSALSlice"
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser"
import { AuthenticatedTemplate, useIsAuthenticated, useMsal, } from "@azure/msal-react"
import { getCurrentUserData, getProfileImages } from "./services/ApiServices"
import { useDispatch, useSelector } from "react-redux"
import { loginRequest } from "./Utils/authConfig"
import { scope } from "./const"
import Loader from "./components/Loader/Loader"
import toast from "react-hot-toast"
import Routes from "./Routes"
import moment from "moment"
import Error from "./components/Error/Error"
import "./App.scss"

export default function App() {
  const accessToken = useSelector((state) => state.MSALAuth.accessToken)
  const currentUser = useSelector((state) => state.MSALAuth.currentUser)

  const isAuthenticated = useIsAuthenticated()
  const { instance, inProgress, accounts } = useMsal()

  const [isLoading, setIsLoading] = useState(false)
  const [intervalTimeForAccessToken, setIntervalTimeForAccessToken] = useState(3600000)

  const dispatch = useDispatch()
  const [hasEnoughRights, setHasEnoughRights] = useState(undefined)
  const [ErrorResponse, setErrorResponse] = useState({})
  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.warn(error))
  }

  const setAccessTokenData = (accessTokenResponse) => {
    // Acquire token silent success
    const accessToken = accessTokenResponse.accessToken

    localStorage.setItem("token", accessToken)
    window.accessToken = accessToken
    window.expToken = accessTokenResponse.idTokenClaims.exp

    dispatch(changeAccessToken(accessToken))

    setIntervalTimeForAccessToken((moment(accessTokenResponse?.expiresOn).diff(moment()._d, "s") + 1) * 1000)
  }

  const handleResponse = (response) => {
    if (response) {
      setAccessTokenData(response)
    } else if (accounts && accounts?.length === 0) {
      handleLogin()
    } else if (accounts && accounts?.length === 1) {
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          setAccessTokenData(accessTokenResponse)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    } else if (accounts && accounts.length > 1) {
      // Add your account choosing logic here
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          setAccessTokenData(accessTokenResponse)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          setAccessTokenData(accessTokenResponse)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    }, intervalTimeForAccessToken)

    return () => { clearInterval(interval) }
  }, [intervalTimeForAccessToken])

  useEffect(() => {
    dispatch(changeAuthenticated(isAuthenticated))
  }, [isAuthenticated])

  useEffect(() => {
    instance.handleRedirectPromise().then(handleResponse).catch((error) => console.warn(error))
  }, [isAuthenticated, inProgress])

  const GetCurrentUser = () => {
    setIsLoading(true)
    getCurrentUserData().then(async (response) => {
      if (response?.Data?.UPN) {
        setHasEnoughRights(true)
        dispatch(changeCurrentUser(response))
        dispatch(changeUserName(response?.Data?.Name))
        dispatch(changeUserEmail(response?.Data?.Email))
        await getProfileImages(response?.Data?.UPN).then((response) => {
          dispatch(changeUserImage(response))
        })
      } else {
        setHasEnoughRights(false)
        if (typeof response === "string") {
          toast.error(response)
          setErrorResponse({ Status: 401, Message: response })
        } else if (response?.ExceptionMessage || response?.Message) {
          setErrorResponse({ Status: 401, Message: response?.ExceptionMessage || response?.Message })
        }
      }
    }).catch((error) => {
      if (typeof response === "string") {
        toast.error(error)
        setErrorResponse({ Status: 401, Message: error })
      } else if (error?.ExceptionMessage || error?.Message) {
        setErrorResponse({ Status: 401, Message: error?.ExceptionMessage || error?.Message })
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (accessToken) {
      GetCurrentUser()
    }
  }, [accessToken])

  return (
    <AuthenticatedTemplate>
      {isLoading && <Loader />}
      {hasEnoughRights === true && accessToken && typeof currentUser?.Data === "object" && Object.keys(currentUser?.Data)?.length > 0 && (
        <Routes />
      )}
      {hasEnoughRights === false && (
        <Error Status={ErrorResponse?.Status} Message={ErrorResponse?.Message} />
      )}
    </AuthenticatedTemplate>
  )
}
