import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import swal from 'sweetalert'
import { addUpdateFunctions, deleteFunction, getAllFunctions } from '../../services/ApiServices'
import Loader from '../../components/Loader/Loader'
import './Function.scss'

const Function = () => {
    const [isLoading, setIsLoading] = useState(false)

    const [showError, setShowError] = useState(false)
    const [showUpdateError, setShowUpdateError] = useState(false)

    const [editedFunction, setEditedFunction] = useState({ id: 0, title: "", isActive: false });

    const [allFunctions, setAllFunctions] = useState([]);
    const [title, setTitle] = useState('');

    // Get All Function
    const GetAllFunctions = async () => {
        setIsLoading(true);

        await getAllFunctions().then((response) => {
            if (response?.Success) {
                setAllFunctions(response?.Data)
            } else {
                setAllFunctions([])
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    // Add Function Data
    const addFunctionsData = async () => {
        if (title) {
            setIsLoading(true);
            setShowError(false)

            const payload = {
                Name: title
            }

            await addUpdateFunctions(payload).then((response) => {
                if (response?.Success === true) {
                    toast.success(response?.Message)
                }
                else {
                    toast.error(response?.Message)
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setIsLoading(false)
                setTitle('')
                GetAllFunctions()
            })
        }
        else {
            setShowError(true)
        }
    };

    // Delete Function Data
    const deleteFunctions = (Id) => {
        const deleteData = async () => {
            setIsLoading(true);

            await deleteFunction(Id).then((response) => {
                toast.success(response?.Message)
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
                GetAllFunctions();
            })
        };

        swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover this Function!',
            icon: 'warning',
            buttons: ['Cancel', 'Yes, delete it!'],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteData();
            } else {
                setIsLoading(false);
            }
        });
    };

    // Update Function Data
    const updateFunction = async () => {
        if (editedFunction.title) {
            setShowUpdateError(false)

            setIsLoading(true);
            const payload = {
                Id: editedFunction?.id,
                Name: editedFunction?.title,
                IsActive: editedFunction?.isActive
            }

            await addUpdateFunctions(payload).then((response) => {
                toast.success(response?.Message)
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setIsLoading(false)
                setTitle('')
                GetAllFunctions()
            })
        }
        else {
            setShowUpdateError(true)
        }
    };

    // Handle Status
    const handleFunctionStatus = async (data) => {
        setIsLoading(true)
        const payload = {
            Id: data?.Id,
            Name: data?.Name,
            IsActive: !data?.IsActive
        }

        await addUpdateFunctions(payload).then((response) => {
            toast.success(response?.Message)
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
            setTitle('')
            GetAllFunctions()
        })
    }

    useEffect(() => {
        GetAllFunctions()
    }, [])

    return (
        <>
            {isLoading && <Loader />}

            <main id='main' className='function-main'>
                <div className='pagetitle mb'>
                    <h1>Functions</h1>
                </div>
                <section className='section'>
                    <div className='add-section row mb'>
                        {/* ===========================Enter Title=========================== */}
                        <div className='col-lg-4 col-md-4 col-sm-4'>
                            <input
                                type='text'
                                className={`form-control ${showError ? "mb-0" : "mb-3"}`}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                    setShowError(false)
                                }}
                                placeholder='Please enter title'
                                required
                            />
                            {!title && showError && <p className='fs-6 text-danger ps-2'>Please enter title!</p>}
                        </div>
                        {/* ===========================Add Button=========================== */}
                        <div className='col-lg-2 col-md-2 col-sm-2'>
                            <button type='button' className='add-btn btn btn-primary btn-lg' onClick={() => addFunctionsData()}>Add</button>
                        </div>
                    </div>

                    {/*=========================== Table ===========================*/}
                    <table className='table tableCustom table-hover'>
                        <thead className='w-100'>
                            <tr className='row'>
                                <th className='col-6' scope='col'>Title</th>
                                <th className='col-6 d-flex justify-content-end align-items-center gap-3' scope='col'>
                                    <div>Status</div>
                                    <div>Actions</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='w-100'>
                            {allFunctions.map((func, Id) => (
                                <tr key={Id} className='row'>
                                    <td className='col-6 txt-medium'>{func?.Name}</td>
                                    <td className='col-6 d-flex buttons gap-3 justify-content-end' align='right'>
                                        <div>
                                            <button
                                                className={`btn active-btn ${func?.IsActive ? "btn-outline-success" : "btn-outline-danger"}`}
                                                onClick={() => {
                                                    setEditedFunction({
                                                        id: func?.Id,
                                                        title: func?.Name,
                                                        isActive: func?.IsActive
                                                    })
                                                    handleFunctionStatus(func)
                                                }}
                                            >
                                                {func?.IsActive ? "Active" : "Inactive"}
                                            </button>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <button
                                                type='button'
                                                data-bs-toggle="modal"
                                                data-bs-target="#editproject"
                                                className='edit-btn btn btn-primary-w btn-icon me-2'
                                                onClick={() => {
                                                    setEditedFunction({
                                                        id: func?.Id,
                                                        title: func?.Name,
                                                        isActive: func?.IsActive
                                                    })
                                                }}
                                            >
                                                <i className="fa-solid fa-pen p-2" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>

                {/* ===========================Modal=========================== */}
                <div className="modal fade" id='editproject' tabIndex={-1} >
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Edit Function</h5>
                                <button type='button' className='btn-close' aria-label='Close'
                                    data-bs-dismiss="modal" />
                            </div>
                            <div className='modal-body mb-4'>
                                <form className='row g-3'>
                                    <div className='col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Title</label>
                                        <input
                                            type='text'
                                            className={`form-control ${showUpdateError ? "mb-0" : "mb-3"}`}
                                            id='inputName4'
                                            value={editedFunction?.title}
                                            onChange={e => {
                                                setEditedFunction({ ...editedFunction, title: e.target.value })
                                                setShowUpdateError(false)
                                            }}
                                            placeholder='Please enter title'
                                            autocomplete="off"
                                        />
                                        {!editedFunction.title && showUpdateError && <p className='fs-6 text-danger ps-2'>Please enter title!</p>}
                                    </div>
                                </form>
                            </div>
                            <div className='modal-footer'>
                                <button
                                    type='button'
                                    className='cancel-btn btn btn-transparent btn-lg m-0 me-2'
                                    data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='button'
                                    data-bs-dismiss={editedFunction?.title ? "modal" : ""}
                                    className='submit-btn btn btn-primary btn-lg m-0'
                                    onClick={() => updateFunction()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Function;



