import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select'
import swal from 'sweetalert';
import { addUpdateServiceCategories, deleteServiceCategories, getAllFunctions, getAllServiceCategories } from '../../services/ApiServices'
import Loader from "../../components/Loader/Loader";
import './ServiceCategories.scss'

const ServiceCategories = () => {
    const [isLoading, setIsLoading] = useState(false)

    const [errorShow, setErrorShow] = useState(false)
    const [errorShowUpdate, setErrorShowUpdate] = useState(false)

    const [editedServiceCategories, setEditedServiceCategories] = useState({ id: 0, title: "", FunctionId: 0, projectSelect: false, isActive: false });

    const [allServiceCategories, setAllServiceCategories] = useState([]);
    const [allFunctions, setAllFunctions] = useState([])

    const [title, setTitle] = useState("");
    const [functionId, setFunctionId] = useState(0)
    const [projectSelect, setProjectSelect] = useState(false)

    // Get All ServiceCategories
    const GetAllServiceCategories = async () => {
        setIsLoading(true)
        await getAllServiceCategories().then((response) => {
            if (response?.Success) {
                setAllServiceCategories(response?.Data)
            } else {
                setAllServiceCategories([])
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    // Get All Function
    const GetAllFunctions = async () => {
        setIsLoading(true);

        await getAllFunctions().then((response) => {
            if (response?.Success) {
                setAllFunctions(response?.Data)
            } else {
                setAllFunctions([])
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    // Add
    const addServiceCategory = async () => {
        if (title && functionId > 0) {
            setIsLoading(true);
            setErrorShow(false)

            const payload = {
                ServiceCategoriesName: title,
                FunctionId: functionId,
                ProjectSelection: projectSelect
            }

            await addUpdateServiceCategories(payload).then((response) => {
                if (response?.Success === true) {
                    toast.success(response?.Message)
                }
                else {
                    toast.error(response?.Message)
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setIsLoading(false)
                setTitle('')
                setProjectSelect(false)
                GetAllServiceCategories()
            })
        } else {
            setErrorShow(true)
        }
    };

    // Delete
    const deleteServiceCategory = async (id) => {
        swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover this project!',
            icon: 'warning',
            buttons: ['Cancel', 'Yes, delete it!'],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteData();
            } else {
                setIsLoading(false);
            }
        });

        const deleteData = async () => {
            setIsLoading(true);

            await deleteServiceCategories(id).then((response) => {
                toast.success(response?.Message)
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
                GetAllServiceCategories();
            })
        }
    };

    // Update
    const updateProject = async () => {
        if (editedServiceCategories?.title.length > 0) {
            setIsLoading(true);
            setErrorShowUpdate(false)

            const payload = {
                Id: editedServiceCategories?.id,
                ServiceCategoriesName: editedServiceCategories?.title,
                FunctionId: editedServiceCategories?.FunctionId,
                ProjectSelection: editedServiceCategories?.projectSelect,
                IsActive: editedServiceCategories?.isActive
            }

            await addUpdateServiceCategories(payload).then((response) => {
                toast.success(response?.Message)
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setIsLoading(false)
                setTitle('')
                GetAllServiceCategories()
            })
        }
        else {
            setErrorShowUpdate(true)
        }
    };

    const handleCateStatus = async (data) => {
        setIsLoading(true);

        const payload = {
            Id: data?.Id,
            ServiceCategoriesName: data?.ServiceCategoriesName,
            FunctionId: data?.FunctionId,
            ProjectSelection: data?.ProjectSelection,
            IsActive: !data?.IsActive
        }

        await addUpdateServiceCategories(payload).then((response) => {
            toast.success(response?.Message)
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
            setTitle('')
            GetAllServiceCategories()
        })
    }

    useEffect(() => {
        GetAllServiceCategories()
        GetAllFunctions()
    }, [])

    return (
        <>
            {isLoading && <Loader />}

            <main id="main" className="cate-main">
                <div className="pagetitle mb">
                    <h1>Service Categories</h1>
                </div>
                <section className="section">
                    {/* =========================== Add Service Categories ===========================  */}
                    <div className="add-section row mb">
                        {/* =========================== Add Title for Service Categories ===========================  */}
                        <div className="col-lg-4 col-md-4 col-sm-4 mb-3">
                            <input
                                type="text"
                                className={`form-control ${errorShow ? "mb-0" : "mb-0"}`}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                    setErrorShow(false)
                                }}
                                placeholder='Please enter title'
                            />
                            {!title && errorShow && <p className='fs-6 text-danger ps-2 mb-0'>Please enter title!</p>
                            }
                        </div>
                        {/* =========================== Function =========================== */}
                        <div className="col-lg-3 col-md-3 col-sm-3 mb-3">
                            <Select
                                defaultValue={functionId}
                                onChange={(e) => setFunctionId(Number(e.value))}
                                options={allFunctions.length > 0 &&
                                    allFunctions?.filter((d) => d?.IsActive)?.map((func) => (
                                        ({ value: func?.Id, label: func?.Name })
                                    ))}
                                className={`form-control p-1 ${errorShow ? "mb-0" : "mb-0"}`}
                                placeholder="Select function"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        marginTop: 2,
                                        border: "none",
                                        boxShadow: "none",
                                    }),
                                }}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                            {functionId === 0 && errorShow && <p className='fs-6 text-danger ps-2 mb-0'>Please select categories!</p>}
                        </div>
                        {/* ======================== Checkbox for Project ========================  */}
                        <div className="col-lg-2 col-md-3 col-sm-3 mb-3">
                            <div className='d-flex align-items-center mt-3'>
                                <label
                                    className={`txt-medium me-2 ${errorShow ? "mb-0" : "mb-3"}`}
                                    htmlFor="project"
                                >
                                    Project Selection :
                                </label>
                                <input
                                    type="checkbox"
                                    className={`form-check-input m-0 ${errorShow ? "mb-0" : "mb-3"}`}
                                    id="project"
                                    checked={projectSelect}
                                    onChange={(e) => setProjectSelect(e.target.checked)}
                                    style={{ height: "1.5rem", width: "1.5rem" }}
                                />
                            </div>
                            {(!title || functionId === 0) && errorShow && <p className='fs-6 text-danger ps-2 mb-0'></p>}
                        </div>
                        {/* =========================== Add Button ===========================  */}
                        <div className="col-lg-2 col-md-2 col-sm-2 mb-3">
                            <button type="button" className="add-btn btn btn-primary btn-lg" onClick={() => addServiceCategory()}>Add</button>
                        </div>
                    </div>

                    {/* =========================== Table Service Categories ===========================*/}
                    <table className="table tableCustom">
                        <thead className='w-100'>
                            <tr className='row'>
                                <th className='col-4' scope="col">Title</th>
                                <th className='col-3' scope="col">Function</th>
                                <th className='col-2' scope="col">Project Selection</th>
                                <th className='col-3 d-flex justify-content-end gap-3' scope="col">
                                    <div>Status</div>
                                    <div>Actions</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='w-100'>
                            {allServiceCategories &&
                                allServiceCategories.length > 0 &&
                                allServiceCategories.map((categories, index) => (
                                    <tr key={index} className='row'>
                                        <td className='col-4 txt-medium'>{categories?.ServiceCategoriesName}</td>
                                        <td className='col-3 txt-medium'>{categories?.FunctionName}</td>
                                        <td className='col-2 txt-medium'>{categories?.ProjectSelection ? "Yes" : "No"}</td>
                                        <td className='col-3 d-flex justify-content-end gap-3 buttons' align='right'>
                                            <div>
                                                <button
                                                    className={`btn active-btn ${categories?.IsActive ? "btn-outline-success" : "btn-outline-danger"}`}
                                                    onClick={() => {
                                                        setEditedServiceCategories({
                                                            id: categories?.Id,
                                                            title: categories?.ServiceCategoriesName,
                                                            FunctionId: categories?.FunctionId,
                                                            projectSelect: categories?.ProjectSelection,
                                                            isActive: categories?.IsActive
                                                        })
                                                        handleCateStatus(categories)
                                                    }}
                                                >
                                                    {categories?.IsActive ? "Active" : "Inactive"}
                                                </button>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <button
                                                    type="button"
                                                    className="edit-btn btn btn-primary-w btn-icon me-2"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editproject"
                                                    onClick={() => setEditedServiceCategories({
                                                        id: categories?.Id,
                                                        title: categories?.ServiceCategoriesName,
                                                        FunctionId: categories?.FunctionId,
                                                        projectSelect: categories?.ProjectSelection,
                                                        isActive: categories?.IsActive
                                                    })}
                                                >
                                                    <i className="fa-solid fa-pen p-2" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </section>

                {/* =========================== Modal Service Categories =========================== */}
                <div className="modal fade" id='editproject'>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Service Categories</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" />
                            </div>
                            <div className="modal-body mb-4">
                                <form className="row g-3">
                                    <div className="col-12 mb-3 m-0">
                                        <label htmlFor="inputName4" className="form-label">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputName4"
                                            value={editedServiceCategories?.title}
                                            onChange={e => {
                                                setEditedServiceCategories({ ...editedServiceCategories, title: e.target.value })
                                                setErrorShowUpdate(false)
                                            }}
                                            placeholder='Please enter title'
                                            autocomplete="off"
                                        />
                                        {!editedServiceCategories?.title && errorShowUpdate &&
                                            <p className='fs-6 text-danger ps-2'>Please enter title!</p>
                                        }
                                    </div>
                                    <div className="col-12 mb-3 m-0">
                                        <label htmlFor="inputName4" className="form-label">Function</label>
                                        <Select
                                            value={allFunctions?.length > 0 ? ({
                                                label: allFunctions?.find((func) => func?.Id === editedServiceCategories?.FunctionId)?.Name,
                                                value: editedServiceCategories?.FunctionId
                                            }) : (
                                                {}
                                            )}
                                            onChange={(e) => setEditedServiceCategories({ ...editedServiceCategories, FunctionId: Number(e.value) })}
                                            options={allFunctions?.length > 0 ? (
                                                allFunctions?.filter((d) => d?.IsActive)?.map((func) => ({
                                                    label: func?.Name,
                                                    value: func?.Id
                                                }))
                                            ) : (
                                                []
                                            )}
                                            className={`form-control p-1 ${errorShow ? "mb-0" : "mb-3"}`}
                                            placeholder="Select categories"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                        {functionId === 0 && errorShow && <p className='fs-6 text-danger ps-2'>Please select categories!</p>}
                                    </div>
                                    <div className="col-6 mb-3 m-0 d-flex align-items-center">
                                        <label className="form-label me-2 m-0" htmlFor="modal-project">Project Selection :</label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="modal-project"
                                            checked={editedServiceCategories?.projectSelect}
                                            onChange={(e) => setEditedServiceCategories({ ...editedServiceCategories, projectSelect: e.target.checked })}
                                            style={{ height: "1.5rem", width: "1.5rem" }}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    className="cancel-btn btn btn-transparent btn-lg m-0 me-2"
                                    onClick={() => setErrorShowUpdate(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    data-bs-dismiss={`${editedServiceCategories?.title ? "modal" : ""}`}
                                    className="submit-btn btn btn-primary btn-lg m-0"
                                    onClick={() => updateProject()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </>
    );
};

export default ServiceCategories;