import React, { useEffect, useState } from 'react'
import "./ImportHolidays.scss"
import { getAllCountry, importHolidays, insertUpdateHolidays } from '../../services/ApiServices';
import moment from 'moment';
import ReactSelect from 'react-select';
import ReactDatePicker from 'react-datepicker';
import Loader from '../../components/Loader/Loader';
import { error } from 'jquery';
import toast from 'react-hot-toast';

const ImportHolidays = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [allCountry, setAllCountry] = useState([])
    const [holidays, setHolidays] = useState([])
    const [countryCode, setCountryCode] = useState(0);
    const [selectedYear, setSelectedYear] = useState(moment()._d)

    const [yearError, setYearError] = useState(false)
    const [countryError, setCountryError] = useState(false)

    const GetAllCountry = async () => {
        setIsLoading(true)
        await getAllCountry().then((response) => {
            setAllCountry([...response?.Data])
        }).catch((error) => {
            console.warn(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleYearChange = (date) => {
        if (date) {
            setYearError(false)
            setSelectedYear(date)
        } else {
            setYearError(true)
        }
    }

    const saveHolidays = async () => {
        const payload = holidays?.filter((f) => f?.checked === true)?.map((a) => ({
            Name: a?.Name,
            CountryId: allCountry?.find((p) => p?.Name === a?.Country)?.Id,
            Date: a?.Date,
            IsActive: a?.IsActive
        }))

        setIsLoading(true)
        await insertUpdateHolidays(payload).then((response) => {
            if (response?.Success) {
                toast.success("Holidays imported successfully!")
                setHolidays([])
            }
        }).catch((error) => {
            console.warn(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleImport = async () => {
        if (countryCode === 0) {
            setCountryError(true)
        } else if (!selectedYear) {
            setYearError(true)
        } else {
            setIsLoading(true)
            await importHolidays(moment(selectedYear).year(), countryCode).then((response) => {
                if (response?.Success) {
                    const removedDuplicates = () => {
                        return response?.Data?.filter((item, index, self) =>
                            index === self.findIndex(t => JSON.stringify(t) === JSON.stringify(item))
                        );
                    };
                    setHolidays(removedDuplicates()?.map((h) => ({ ...h, checked: true })))
                }
            }).catch((error) => {
                console.warn(error)
            }).finally(() => {
                setIsLoading(false)
                setSelectedYear(moment()._d)
                setYearError(false)
                setCountryError(false)
            })
        }


    }

    const handleCheck = (h) => {
        const updateArray = holidays.map((a) => (h?.Name === a?.Name && h?.Date === a?.Date) ? { ...a, checked: !a?.checked } : a)
        setHolidays(updateArray)
    }

    useEffect(() => {
        GetAllCountry()
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            <main id="main" className='import-holidays-main'>
                <div className='pagetitle'>
                    <h1>Import Holidays</h1>
                </div>
                <section className='section'>
                    <div className="row mb-4">
                        <div className='row d-flex justify-content-start'>
                            <div className='d-flex justify-content-start gap-3'>
                                <div className='col-3'>
                                    <ReactSelect
                                        value={{
                                            value: countryCode,
                                            label: countryCode === 0 ? "Select country" : allCountry?.find((c) => c?.Code === countryCode)?.Name
                                        }}
                                        onChange={(e) => setCountryCode(e.value)}
                                        options={[
                                            { value: 0, label: "Select Country" },
                                            ...allCountry.sort((a, b) => a.Name.localeCompare(b.Name)).map((c) => (
                                                ({ value: c?.Code, label: c?.Name })
                                            ))
                                        ]}
                                        className={`form-control p-1 `}
                                        placeholder="Select Country"
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                marginTop: 2,
                                                border: "none",
                                                boxShadow: "none",
                                            }),
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                    {countryError && <small>Please select country!</small>}
                                </div>
                                <div className='col-2'>
                                    <ReactDatePicker
                                        dateFormat="yyyy"
                                        showYearPicker
                                        selected={selectedYear}
                                        onChange={handleYearChange}
                                        className='form-control'
                                        placeholderText='Select year'
                                    />
                                    {yearError && <small>Please select valid year!</small>}
                                </div>
                                <div>
                                    <button
                                        type='button'
                                        className='add-btn btn btn-primary btn-lg '
                                        onClick={() => handleImport()}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* =======================table============================== */}
                    {holidays.length > 0 &&
                        <table className='table tableCustom table-hover  '>
                            <thead >
                                <tr className='row mb-1'>
                                    <th className='col' scope='col'>Checked</th>
                                    <th className='col' scope='col'>Name </th>
                                    <th className='col' scope='col'>Date</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                {holidays?.map((h, Id) => (
                                    <tr
                                        key={Id}
                                        className='row'
                                    >
                                        <td className="col">
                                            <input
                                                type="checkbox"
                                                className="form-check-input ms-3"
                                                id="modal-project"
                                                checked={h?.checked}
                                                onChange={() => handleCheck(h)}
                                                style={{ height: "1.5rem", width: "1.5rem" }}
                                            />
                                        </td>
                                        <td className='col txt-medium'>
                                            {h?.Name}
                                        </td>
                                        <td className="col">{moment(h?.Date).format("YYYY-MM-DD")}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }

                    {/* ====================================save button =================== */}
                    {holidays?.length > 0 && <div className='add-section d-flex justify-items-end mb'>
                        <div className=' col  '>
                            <button type='button'
                                className='add-btn btn btn-primary d-flex justify-content-center align-self-end btn-lg'
                                onClick={() => saveHolidays()}
                                style={{ marginLeft: "auto" }}
                            >
                                Save
                            </button>
                        </div>
                    </div>}
                </section>
            </main>
        </>
    )
}

export default ImportHolidays
