export const router = {

  currentUser: "api/User/CurrentUser",
  getAllUser: "api/User/GetAll",
  updateUser: "api/User/UpdateUser",
  getProfileImage: "api/User/ProfileImage",

  getAllProject: "api/Project/GetAll",
  addUpdateProject: "api/Project/CreateUpdate",
  deleteProject: "api/Project/Delete",

  getAllServiceCategories: "api/ServiceCategories/GetAll",
  addUpdateServiceCategories: "api/ServiceCategories/CreateUpdate",
  deleteServiceCategories: "api/ServiceCategories/Delete",

  getAllServiceDisciplines: "api/ServiceDisciplines/GetAll",
  addUpdateServiceDisciplines: "api/ServiceDisciplines/CreateUpdate",
  deleteServiceDisciplines: "api/ServiceDisciplines/Delete",

  getAllFunctions: "api/Function/GetAll",
  addUpdateFunction: "api/Function/CreateUpdate",
  deleteFunction: "api/Function/Delete",

  getAllTimesheets: "api/TimeSheet/GetAll",
  insertUpdateTimesheets: "api/TimeSheet/InsertUpdateTimeSheets",
  insertUpdateTimesheetsForMobile: "api/TimeSheet/InsertUpdateTimeSheetsForMobile",
  createUpdateTimesheet: "api/TimeSheet/CreateUpdate",
  submitTimesheets: "api/TimeSheet/SubmitUserTimeSheet",
  deleteTimesheet: "api/TimeSheet/Delete",

  getUserPreference: "api/Preference/GetUserPreference",

  getUserFavourite: "/api/Favourite/GetUserFavourite",
  getAzureADUsers: "/api/User/AzureADUsers",
  getEditUserDetails: "/api/User/EditUser",
  deleteFavourite: "/api/Favourite/Delete",
  createUpdate: "/api/Favourite/CreateUpdate",

  getApprovalTimesheets: "api/Approval/GetAllApproval",
  updateUserApproval: "api/Approval/UpdateUserApproval",

  getSubmission: "/api/TimesheetSubmission/GetSubmission",
  createUpdateSubmission: "/api/TimesheetSubmission/CreateUpdate",

  saveImportData: "api/TimeSheet/SaveImportData",
  submitImportData: "api/TimeSheet/SubmitImportData",

  getAllCountry: "api/Holiday/GetAllCountries",
  getAllHoliday: "/api/Holiday/GetAllHoliday",
  getHolidayByCountryAndYear: "api/Holiday/GetHolidayByCountryAndYear",
  createUpdateHoliday: "/api/Holiday/CreateUpdate",
  deleteHoliday: "/api/Holiday/Delete",
  importHolidays: "/api/Holiday/ImportHolidays",
  insertUpdateHolidays: "/api/Holiday/InsertUpdateHolidays",

  updatePreferredCountry: "api/User/UserPreferredCountry",
  getUserDetail: "/api/User/GetUser",

  getMyEmployeesTimesheets: "api/Report/EmployeesTimeSheetsReport",
  getManagerEmployees: "api/Report/GetManagerEmployees",
  getReportTimesheets: "api/Report/TimeSheetsReport",
  insertUpdateNotification: "/api//Notification/InsertUpdateNotification",
  getNotification: "/api//Notification/GetNotification",

  getAllPeriods: "api/LockTimesheet/GetAll",
  createUpdatePeriod: "api/LockTimesheet/CreateUpdate",
  submitAll: "api/LockTimesheet/SubmitAll",
}

export default router;
