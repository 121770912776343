import React, { useEffect, useState } from "react"
import moment from "moment"
import "./Header.scss"
import { iconCiSC } from "../../assets/img/icons"
import { useDispatch, useSelector } from "react-redux"
import { loginRequest } from "../../Utils/authConfig"
import { useMsal } from "@azure/msal-react"
import { changeAuthenticated } from "../../Store/Slices/MSALSlice"

import iconUser from "../../assets/img/img-placeholder.png"
import { Link, useNavigate } from "react-router-dom"

export default function Header() {
    const profileImg = useSelector((state) => state.MSALAuth.userImage)
    const currentUser = useSelector((state) => state.MSALAuth.currentUser)
    const { instance } = useMsal()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleLogout = () => {
        instance.logoutRedirect(loginRequest)
        dispatch(changeAuthenticated(false))
    }

    const toggleSideBar = () => {
        if (currentUser?.Data?.IsAdmin) {
            const body = document.getElementById("body")
            if (body) {
                if (body?.classList.contains("toggle-sidebar")) {
                    body?.classList.remove("toggle-sidebar")
                } else {
                    body?.classList.add("toggle-sidebar")
                }
            }
        }
    }

    useEffect(() => {
        const body = document.getElementById("body")
        if (!currentUser?.Data?.IsAdmin) {
            if (!body?.classList.contains("toggle-sidebar")) {
                body?.classList.add("toggle-sidebar")
            }
        } else {
            if (body?.classList.contains("toggle-sidebar")) {
                body?.classList.remove("toggle-sidebar")
            }
        }
    }, [currentUser])

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="header-inner d-flex align-items-center">
                {currentUser?.Data?.IsAdmin &&
                    <i className="fa-solid fa-bars toggle-sidebar-btn pe-3" onClick={() => toggleSideBar()} />
                }
                <div className="d-flex align-items-center justify-content-between">
                    <a href="/" className="logo d-flex align-items-center">
                        {/* <img className="logo-sec" src={iconCiSC} alt="" /> */}
                        {iconCiSC}
                    </a>
                </div>
            </div>
            <div className="header-date align-items-center offset-xl-4 date">
                <span>{moment().format("MMMM D, YYYY, hh:mm A")}</span>
            </div>
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    <li className="nav-item dropdown">
                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="/" data-bs-toggle="dropdown">
                            <img src={profileImg ?? iconUser} alt="Profile" className="rounded-circle" />
                            <span className="d-md-block dropdown-toggle pe-2">Hi, <span>{currentUser?.Data?.Name}</span></span>
                            <button className="dropdown">
                                <i className="icon">
                                    <i className="fa-solid fa-chevron-down" />
                                </i>
                            </button>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>{currentUser?.Data?.Name}</h6>
                                <span>{currentUser?.Data?.Email}</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <div onClick={() => navigate("/favourite")} className="dropdown-item d-flex align-items-center">
                                    <i className="bi bi-person" />
                                    <span>Favourites</span>
                                </div>
                            </li>
                            <li>
                                <div onClick={() => navigate("/preferred-location")} className="dropdown-item d-flex align-items-center">
                                    <i className="bi bi-person" />
                                    <span>Preferred Location</span>
                                </div>
                            </li>
                            <li>
                                <div onClick={() => navigate("/report", { state: { key: false } })} className="dropdown-item d-flex align-items-center">
                                    <i className="bi bi-person" />
                                    <span>Report</span>
                                </div>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            {/* <li>
                                <a className="dropdown-item d-flex align-items-center" href="/">
                                    <i className="bi bi-gear" />
                                    <span>Account Settings</span>
                                </a>
                            </li> */}
                            {/* <li>
                                <hr className="dropdown-divider" />
                            </li> */}
                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="#" onClick={() => handleLogout()}>
                                    <i className="bi bi-box-arrow-right" />
                                    <span>Log Out</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav >
        </header >
    )
}
