import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./index.scss";
import "./assets/css/style.css"
import "./assets/css/custom.css";
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./Utils/authConfig";
import { store } from "./Store/store";
import { Toaster } from "react-hot-toast";

const msalInstance = new PublicClientApplication(msalConfig)
await msalInstance.initialize();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
        <Toaster position="top-right" reverseOrder={false} gutter={8} />
      </MsalProvider>
    </Provider>
  </BrowserRouter>
);

reportWebVitals();