import axiosConnectorInstance from "./AxiosConnector"
import router from "../Utils/APIUrls"

export const getCurrentUserData = async () => {
  try {
    const response = await axiosConnectorInstance.get(router?.currentUser, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const getAllUsers = async () => {
  try {
    const response = await axiosConnectorInstance.get(router?.getAllUser, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const addUpdateUser = async (data) => {
  try {
    const response = await axiosConnectorInstance.put(router?.updateUser, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getProfileImages = async (id) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getProfileImage}?UPN=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}


export const getProjects = async (id) => {
  try {
    const response = await axiosConnectorInstance.get(router?.getAllProject, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const addUpdateProject = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.addUpdateProject, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const deleteProject = async (Id) => {
  try {
    const response = await axiosConnectorInstance.delete(`${router?.deleteProject}?Id=${Id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getAllServiceCategories = async () => {
  try {
    const response = await axiosConnectorInstance.get(router?.getAllServiceCategories, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const addUpdateServiceCategories = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.addUpdateServiceCategories, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const deleteServiceCategories = async (id) => {
  try {
    const response = await axiosConnectorInstance.delete(`${router?.deleteServiceCategories}/?Id=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getServiceDesiplines = async () => {
  try {
    const response = await axiosConnectorInstance.get(router?.getAllServiceDisciplines, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const addUpdateServiceDesiplines = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.addUpdateServiceDisciplines, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const deleteServiceDesiplines = async (Id) => {
  try {
    const response = await axiosConnectorInstance.delete(`${router?.deleteServiceDisciplines}/?Id=${Id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getAllFunctions = async () => {
  try {
    const response = await axiosConnectorInstance.get(router?.getAllFunctions, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const addUpdateFunctions = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.addUpdateFunction, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const deleteFunction = async (Id) => {
  try {
    const response = await axiosConnectorInstance.delete(`${router?.deleteFunction}/?Id=${Id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getAllTimeSheets = async (startDate, endDate, user) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getAllTimesheets}?startDate=${startDate}&endDate=${endDate}&user=${user}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const insertUpdateTimesheets = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.insertUpdateTimesheets, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const insertUpdateTimesheetsForMobile = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.insertUpdateTimesheetsForMobile, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const createUpdateTimesheet = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.createUpdateTimesheet, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const submitTimesheet = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.submitTimesheets, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const deleteTimeSheet = async (ids) => {
  try {
    const response = await axiosConnectorInstance.delete(router?.deleteTimesheet, {
      data: ids,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getPreferences = async (email) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getUserPreference}?email=${email}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const createUpdateFavourite = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.createUpdate, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getUserFavourite = async (email) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getUserFavourite}?email=${email}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const deleteFavourite = async (id) => {
  try {
    const response = await axiosConnectorInstance.delete(`${router?.deleteFavourite}?Id=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getApprovalTimeSheets = async (startDate, endDate,) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getApprovalTimesheets}?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const updateApproval = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.updateUserApproval, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getSubmission = async () => {
  try {
    const response = await axiosConnectorInstance.get(router?.getSubmission, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const createUpdateSubmission = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.createUpdateSubmission, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getAzureADUsers = async (text) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getAzureADUsers}?searchText=${text}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getEditUserDetails = async (upn) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getEditUserDetails}?UPN=${upn}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const saveImportData = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.saveImportData, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const submitImportData = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.submitImportData, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getAllCountry = async (upn) => {
  try {
    const response = await axiosConnectorInstance.get(router?.getAllCountry, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getAllHoliday = async () => {
  try {
    const response = await axiosConnectorInstance.get(router?.getAllHoliday, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getHolidayByCountryAndYear = async (year, countryId) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getHolidayByCountryAndYear}?year=${year}&countryId=${countryId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const createUpdateHoliday = async (payload) => {
  try {
    const response = await axiosConnectorInstance.post(router?.createUpdateHoliday, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const deleteHoliday = async (id) => {
  try {
    const response = await axiosConnectorInstance.delete(`${router?.deleteHoliday}?Id=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const importHolidays = async (year, code) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.importHolidays}?Year=${year}&CountryCode=${code}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const insertUpdateHolidays = async (data) => {
  try {
    const response = await axiosConnectorInstance.post(router?.insertUpdateHolidays, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const updatePreferredCountry = async (upn, countryId) => {
  try {
    const response = await axiosConnectorInstance.put(`${router?.updatePreferredCountry}?UPN=${upn}&CountryId=${countryId}`, "", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getUserDetails = async (upn) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getUserDetail}?UPN=${upn}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getReportTimesheets = async (startDate, endDate) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getReportTimesheets}?StartDate=${startDate}&EndDate=${endDate}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getMyEmployeesTimesheets = async (startDate, endDate) => {
  try {
    const response = await axiosConnectorInstance.get(`${router?.getMyEmployeesTimesheets}?StartDate=${startDate}&EndDate=${endDate}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getNotification = async () => {
  try {
    const response = await axiosConnectorInstance.get(router?.getNotification, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getManagerEmployees = async () => {
  try {
    const response = await axiosConnectorInstance.get(router?.getManagerEmployees, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const insertUpdateNotification = async (payload) => {
  try {
    const response = await axiosConnectorInstance.post(router?.insertUpdateNotification, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getAllPeriods = async () => {
  try {
    const response = await axiosConnectorInstance.get(router?.getAllPeriods, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const createUpdatePeriod = async (payload) => {
  try {
    const response = await axiosConnectorInstance.post(router?.createUpdatePeriod, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}
export const submitAllPeriod = async (month, year) => {
  try {
    const response = await axiosConnectorInstance.post(`${router?.submitAll}?Month=${month}&Year=${year}`, "", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}