import React, { useEffect, useState } from 'react'
import "./Periods.scss"
import { createUpdatePeriod, getAllPeriods, submitAllPeriod } from '../../services/ApiServices'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import toast from 'react-hot-toast'
import Loader from '../../components/Loader/Loader'

const Periods = () => {
    const [isLoading, setIsLoading] = useState(false)

    const [allPeriods, setAllPeriods] = useState([])
    const [inputPeriodMonth, setInputPeriodMonth] = useState(moment().format("MMM, YYYY"))

    const renderMonthContent = (month, shortMonth, longMonth, day) => {
        const fullYear = new Date(day).getFullYear()
        const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`

        return <span title={tooltipText}>{shortMonth}</span>
    }

    const addPeriod = async () => {
        const alreadyExist = (allPeriods ?? [])?.some((period) => (
            moment(`${period?.Month}-${period?.Year}`, "MMM-YYYY").format("MM-YYYY") === moment(inputPeriodMonth, "MMM, YYYY").format("MM-YYYY")
        ))

        const payload = {
            "Month": moment(inputPeriodMonth).format("MMM"),
            "Year": moment(inputPeriodMonth).format("YYYY"),
            "IsActive": true
        }

        if (alreadyExist) {
            toast.error("Record already exist!")
        } else {
            createUpdate(payload)
        }
    }

    const handlePeriodStatus = (data) => {
        const payload = {
            "Id": data?.Id,
            "Month": data?.Month,
            "Year": data?.Year,
            "IsActive": !data?.IsActive
        }
        createUpdate(payload)
    }

    const createUpdate = async (data) => {
        setIsLoading(true)

        await createUpdatePeriod(data).then((response) => {
            if (response?.Success) {
                toast.success(response?.Message)
                window.location.reload()
                // GetAllPeriods()
            } else {
                toast.error(response?.Message)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleSubmitAll = async (data) => {
        setIsLoading(true)

        await submitAllPeriod(data?.Month, data?.Year).then((response) => {
            if (response?.Success) {
                toast.success(response?.Message)
            } else {
                toast.error(response?.Message)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const monthOrder = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const GetAllPeriods = async () => {
        setIsLoading(true)

        await getAllPeriods().then((response) => {
            if (response?.Success) {
                const data = response?.Data.length > 0
                    ? response?.Data.sort((a, b) => {
                        if (a.Year === b.Year) {
                            return monthOrder.indexOf(a.Month) - monthOrder.indexOf(b.Month);
                        }
                        return a.Year - b.Year;
                    })
                    : []
                setAllPeriods(data)
            } else {
                setAllPeriods([])
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        GetAllPeriods()
    }, [])

    return (
        <>
            {isLoading && <Loader />}

            <main id='main' className='period-main'>
                <div className='pagetitle'>
                    <h1>Period</h1>
                </div>
                <section className='section'>
                    <div className='add-section row mb'>
                        {/* =========================== Enter Title =========================== */}
                        <div className='col-lg-2 col-md-2 col-sm-2'>
                            <ReactDatePicker
                                selected={inputPeriodMonth}
                                dateFormat="MMM, yyyy"
                                renderMonthContent={renderMonthContent}
                                showMonthYearPicker
                                className='form-control month-picker'
                                onChange={(date) => date && setInputPeriodMonth(moment(date).format("MMM, YYYY"))}
                                calendarStartDay={1}
                                todayButton="Today"
                            />
                        </div>
                        {/* =========================== Add Button =========================== */}
                        <div className='col-lg-2 col-md-2 col-sm-2'>
                            <button
                                type='button'
                                className='add-btn btn btn-primary btn-lg'
                                onClick={() => addPeriod()}
                            >
                                Add
                            </button>
                        </div>
                    </div>

                    {/* =========================== Table =========================== */}
                    <table className='table tableCustom table-hover'>
                        <thead className='w-100'>
                            <tr className='row'>
                                <th className='col-6' scope='col'>Month - Year</th>
                                <th className='col-6 d-flex justify-content-end align-items-center gap-3' scope='col'>
                                    <div className='col-3 text-center'>Status</div>
                                    <div className='col-3 text-center'>Submit</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='w-100'>
                            {allPeriods &&
                                allPeriods.length > 0 &&
                                allPeriods.map((period, index) =>
                                    <tr key={index} className='row'>
                                        <td className='col-6 d-flex align-items-center txt-medium'>{period?.Month + " " + period?.Year}</td>
                                        <td className='col-6 d-flex justify-content-end align-items-center gap-3 buttons' align='right'>
                                            <div className='col-3 text-center'>
                                                <button
                                                    className={`btn active-btn ${period?.IsActive ? "btn-outline-success" : "btn-outline-danger"}`}
                                                    onClick={() => handlePeriodStatus(period)}
                                                >
                                                    {period?.IsActive ? "Active" : "Inactive"}
                                                </button>
                                            </div>
                                            <div className='col-3 text-center'>
                                                <button
                                                    className={`btn active-btn btn-outline-success`}
                                                    onClick={() => handleSubmitAll(period)}
                                                    disabled={!period?.IsActive}
                                                >
                                                    Submit all
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </section>
            </main>
        </>
    )
}

export default Periods