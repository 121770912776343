import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'
import './ServiceDiscipline.scss'
import { addUpdateServiceDesiplines, deleteServiceDesiplines, getAllServiceCategories, getServiceDesiplines } from '../../services/ApiServices'
import Loader from '../../components/Loader/Loader'
import toast from 'react-hot-toast'
import Select from 'react-select'

const ServiceDiscipline = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [editedServiceDispline, setEditedserviceDispline] = useState({ id: 0, title: "", ServiceCategoriesId: 0, ServiceCategoriesName: "", IsDefaultHoliday: false, isActive: false });
    const [allServiceDispline, setAllServiceDispline] = useState([]);
    const [title, setTitle] = useState('');
    const [showError, setShowError] = useState(false)
    const [showUpdateError, setShowUpdateError] = useState(false)
    const [allServiceCategories, setAllServiceCategories] = useState([])
    const [serviceCategoryId, setServiceCategoryId] = useState(0)

    // Get All ServiceDispline
    const getAllServiceDisplines = async () => {
        setIsLoading(true);

        await getServiceDesiplines().then((response) => {
            if (response?.Success) {
                setAllServiceDispline(response?.Data)
            } else {
                setAllServiceDispline([])
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    // Get All ServiceCategories
    const GetAllServiceCategories = async () => {
        setIsLoading(true)
        await getAllServiceCategories().then((response) => {
            if (response?.Success) {
                setAllServiceCategories(response?.Data)
            } else {
                setAllServiceCategories([])
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    // Add ServiceDispline Data
    const addServiceDisplineData = async () => {
        if (title && serviceCategoryId > 0) {
            setIsLoading(true);
            setShowError(false)

            const payload = {
                ServiceDisciplinesName: title,
                ServiceCategoriesId: serviceCategoryId
            }

            await addUpdateServiceDesiplines(payload).then((response) => {
                if (response?.Success === true) {
                    toast.success(response?.Message)
                }
                else {
                    toast.error(response?.Message)
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setIsLoading(false)
                setTitle('')
                getAllServiceDisplines()
            })
        }
        else {
            setShowError(true)
        }
    };

    // Delete ServiceDispline Data
    const deleteServiceDispline = (Id) => {
        const deleteData = async () => {
            setIsLoading(true);

            await deleteServiceDesiplines(Id).then((response) => {
                toast.success(response?.Message)
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
                getAllServiceDisplines();
            })
        };

        swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover this ServiceDispline!',
            icon: 'warning',
            buttons: ['Cancel', 'Yes, delete it!'],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteData();
            } else {
                setIsLoading(false);
            }
        });
    };

    // Update ServiceDispline Data
    const updateServiceDispline = async () => {
        if (editedServiceDispline.title) {
            setShowUpdateError(false)

            setIsLoading(true);
            const payload = {
                Id: editedServiceDispline?.id,
                ServiceDisciplinesName: editedServiceDispline?.title,
                ServiceCategoriesId: editedServiceDispline?.ServiceCategoriesId,
                IsDefaultHoliday: editedServiceDispline?.IsDefaultHoliday,
                IsActive: editedServiceDispline?.isActive
            }

            await addUpdateServiceDesiplines(payload).then((response) => {
                toast.success(response?.Message)
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setIsLoading(false)
                setTitle('')
                getAllServiceDisplines()
            })
        }
        else {
            setShowUpdateError(true)
        }
    };

    const handleDiscStatus = async (data) => {
        setIsLoading(true);
        const payload = {
            Id: data?.Id,
            ServiceDisciplinesName: data?.ServiceDisciplinesName,
            ServiceCategoriesId: data?.ServiceCategoriesId,
            IsDefaultHoliday: data?.IsDefaultHoliday,
            IsActive: !data?.IsActive
        }

        await addUpdateServiceDesiplines(payload).then((response) => {
            toast.success(response?.Message)
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
            setTitle('')
            getAllServiceDisplines()
        })
    }

    useEffect(() => {
        getAllServiceDisplines()
        GetAllServiceCategories()
    }, [])

    return (
        <>
            {isLoading && <Loader />}

            <main id='main' className='disci-main'>
                <div className='pagetitle mb'>
                    <h1>Service Disciplines</h1>
                </div>
                <section className='section'>
                    <div className='add-section row mb'>
                        {/* =========================== Enter Title for Service Disciplines ==================== */}
                        <div className='col-lg-4 col-md-4 col-sm-4'>
                            <input
                                type='text'
                                className={`form-control ${showError ? "mb-0" : "mb-3"}`}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                    setShowError(false)
                                }}
                                placeholder='Please enter title'
                                required
                            />
                            {!title && showError && <p className='fs-6 text-danger ps-2'>Please enter title!</p>}
                        </div>
                        {/* =========================== Service Category Function =========================== */}
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <Select
                                defaultValue={serviceCategoryId}
                                onChange={(e) => setServiceCategoryId(Number(e.value))}
                                options={allServiceCategories.length > 0 &&
                                    allServiceCategories?.filter((d) => d?.IsActive)?.map((category) =>
                                        ({ value: category?.Id, label: `${category?.ServiceCategoriesName} (${category?.FunctionName})` })
                                    )}
                                className="form-control p-1"
                                placeholder="Select service category"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        marginTop: 2,
                                        border: "none",
                                        boxShadow: "none",
                                    }),
                                }}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                            {serviceCategoryId === 0 && showError && <p className='fs-6 text-danger ps-2'>Please select function!</p>}
                        </div>
                        {/*=========================== Add Button for Service Disciplines =======================*/}
                        <div className='col-lg-2'>
                            <button type='button' className='add-btn btn btn-primary btn-lg' onClick={() => addServiceDisplineData()}>Add</button>
                        </div>
                    </div>

                    {/*=========================== Table Service Disciplines ===========================*/}
                    <table className='table tableCustom table-hover'>
                        <thead className='w-100'>
                            <tr className='row'>
                                <th className='col-4' scope='col'>Title</th>
                                <th className='col-6' scope='col'>Service Categories</th>
                                <th className='col-2 d-flex justify-content-end align-items-center gap-3' scope='col'>
                                    <div>Status</div>
                                    <div>Actions</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='w-100'>
                            {allServiceDispline.map((serviceDispline, Id) => (
                                <tr key={Id} className='row'>
                                    <td className='col-4 txt-medium'>{serviceDispline?.ServiceDisciplinesName}</td>
                                    <td className='col-6 txt-medium'>
                                        {serviceDispline?.ServiceCategoriesName}
                                        {allServiceCategories?.find((cat) => cat?.Id === serviceDispline?.ServiceCategoriesId)?.FunctionName
                                            ? " (" + allServiceCategories?.find((cat) => cat?.Id === serviceDispline?.ServiceCategoriesId)?.FunctionName + ")"
                                            : ""
                                        }
                                    </td>
                                    <td className='col-2 d-flex justify-content-end gap-3 buttons' align='right'>
                                        <div>
                                            <button
                                                className={`btn active-btn ${serviceDispline?.IsActive ? "btn-outline-success" : "btn-outline-danger"}`}
                                                onClick={() => {
                                                    setEditedserviceDispline({
                                                        id: serviceDispline?.Id,
                                                        title: serviceDispline?.ServiceDisciplinesName,
                                                        ServiceCategoriesId: serviceDispline?.ServiceCategoriesId,
                                                        ServiceCategoriesName: serviceDispline?.ServiceCategoriesName,
                                                        IsDefaultHoliday: serviceDispline?.IsDefaultHoliday,
                                                        isActive: serviceDispline?.IsActive
                                                    })
                                                    handleDiscStatus(serviceDispline)
                                                }}
                                            >
                                                {serviceDispline?.IsActive ? "Active" : "Inactive"}
                                            </button>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <button
                                                type='button'
                                                data-bs-toggle="modal"
                                                data-bs-target="#editproject"
                                                className='edit-btn btn btn-primary-w btn-icon me-2'
                                                onClick={() => setEditedserviceDispline({
                                                    id: serviceDispline?.Id,
                                                    title: serviceDispline?.ServiceDisciplinesName,
                                                    ServiceCategoriesId: serviceDispline?.ServiceCategoriesId,
                                                    ServiceCategoriesName: serviceDispline?.ServiceCategoriesName,
                                                    IsDefaultHoliday: serviceDispline?.IsDefaultHoliday,
                                                    isActive: serviceDispline?.IsActive
                                                })}
                                            >
                                                <i className="fa-solid fa-pen p-2" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>

                {/*=========================== Modal ===========================*/}
                <div className="modal fade" id="editproject" tabIndex={- 1}>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Edit Service Discipline</h5>
                                <button type='button' className='btn-close' aria-label='Close' data-bs-dismiss="modal" />
                            </div>
                            <div className='modal-body mb-4'>
                                <form className='row g-3'>
                                    <div className='title-div col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Title</label>
                                        <input
                                            type='text'
                                            className={`form-control ${showUpdateError ? "mb-0" : "mb-3"}`}
                                            id='inputName4'
                                            value={editedServiceDispline?.title}
                                            onChange={e => {
                                                setEditedserviceDispline({ ...editedServiceDispline, title: e.target.value })
                                                setShowUpdateError(false)
                                            }}
                                            placeholder='Please enter title'
                                            autocomplete="off"
                                        />
                                        {!editedServiceDispline.title && showUpdateError && <p className='fs-6 text-danger ps-2'>Please enter title!</p>}
                                    </div>
                                    <div className='service-categories col-12 mb-3 m-0'>
                                        <label className='form-label'>Service categories</label>
                                        <Select
                                            value={allServiceCategories?.length > 0 ? ({
                                                label: `${allServiceCategories?.find((cat) => cat?.Id === editedServiceDispline?.ServiceCategoriesId)?.ServiceCategoriesName}
                                             (${allServiceCategories?.find((cat) => cat?.Id === editedServiceDispline?.ServiceCategoriesId)?.FunctionName})`,
                                                value: editedServiceDispline?.ServiceCategoriesId
                                            }) : ({})}
                                            onChange={(e) => setEditedserviceDispline({ ...editedServiceDispline, ServiceCategoriesId: Number(e.value) })}
                                            options={allServiceCategories?.length > 0 ? (
                                                allServiceCategories?.filter((d) => d?.IsActive)?.map((category) => ({
                                                    label: `${category?.ServiceCategoriesName} (${category?.FunctionName})`,
                                                    value: category?.Id
                                                }))
                                            ) : ([])}
                                            className={`form-control p-1 ${showError ? "mb-0" : "mb-3"}`}
                                            placeholder="Select function"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                        {serviceCategoryId === 0 && showError && <p className='fs-6 text-danger ps-2'>Please select function!</p>}
                                    </div>
                                    <div className='holiday-div col-12 mb-3 m-0 d-flex align-items-center'>
                                        <input
                                            type='checkbox'
                                            className="form-check-input mb-0"
                                            id='defaultHoliday'
                                            checked={editedServiceDispline?.IsDefaultHoliday}
                                            onChange={e => {
                                                setEditedserviceDispline({ ...editedServiceDispline, IsDefaultHoliday: !editedServiceDispline?.IsDefaultHoliday })
                                                setShowUpdateError(false)
                                            }}
                                        />
                                        <label htmlFor='defaultHoliday' className='form-label mb-0 d-flex align-items-center'>Make default selection for holiday</label>
                                    </div>
                                </form>
                            </div>
                            <div className='modal-footer'>
                                <button
                                    type='button'
                                    data-bs-dismiss="modal"
                                    className='cancel-btn btn btn-transparent btn-lg m-0 me-2'
                                >
                                    Cancel
                                </button>
                                <button
                                    type='button'
                                    data-bs-dismiss={editedServiceDispline.title ? "modal" : ""}
                                    className='submit-btn btn btn-primary btn-lg m-0'
                                    onClick={() => updateServiceDispline()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </main >
        </>
    );
};

export default ServiceDiscipline;