import React, { useEffect, useState } from 'react'
import { getNotification, insertUpdateNotification } from '../../services/ApiServices'
import { handleError } from '../../const'
import Loader from '../../components/Loader/Loader'
import toast from 'react-hot-toast'
import "./Notifications.scss"

const Notifications = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [notificationInputs, setNotificationInputs] = useState({ id: 0, subject: "", body: "" })

    const InsertUpdateNotification = async () => {
        const notificationPayload = {
            "Id": notificationInputs?.id,
            "Subject": notificationInputs?.subject,
            "Message": notificationInputs?.body
        }

        setIsLoading(true)
        await insertUpdateNotification(notificationPayload).then((response) => {
            if (response?.Success) {
                toast.success(response?.Message)
                GetNotification()
            }
        }).catch((error) => {
            handleError(error)
        }).finally(() => {
            setIsLoading(false)
            setNotificationInputs({ subject: "", body: "" })
        })
    }

    const GetNotification = async () => {
        setIsLoading(true)
        await getNotification().then((response) => {
            setNotificationInputs({ id: response?.Data?.Id ?? 0, subject: response?.Data?.Subject ?? "", body: response?.Data?.Message ?? "" })
        }).catch((error) => {
            handleError(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        GetNotification()
    }, [])

    return (
        <>
            {isLoading && <Loader />}

            <main id="main" className="notifications-main">
                <div className="pagetitle">
                    <h1>Notifications</h1>
                </div>

                <section className="section">
                    <div className="col-7">
                        <label htmlFor="inputName4" className="form-label">Message</label>
                        <input
                            type="text"
                            className={"form-control mb-3"}
                            id="inputName4"
                            value={notificationInputs?.subject}
                            onChange={(e) => setNotificationInputs({ ...notificationInputs, subject: e.target.value })}
                            placeholder="Enter subject"
                            autocomplete="off"
                        />

                        <textarea
                            className="form-control mb-4"
                            id="exampleFormControlTextarea1"
                            value={notificationInputs?.body}
                            onChange={(e) => setNotificationInputs({ ...notificationInputs, body: e.target.value })}
                            placeholder="Enter Message"
                            rows="6"
                        />
                    </div>

                    <button
                        type="button"
                        onClick={() => InsertUpdateNotification()}
                        className="save-btn btn btn-primary btn-lg"
                    >
                        Save
                    </button>
                </section>
            </main>
        </>
    )
}

export default Notifications
