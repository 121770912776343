import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticated: false,
  accessToken: "",
  userName: "",
  userEmail: "",
  userImage: {},
  currentUser:{}
};

export const MSALSlice = createSlice({
  name: "MSAL",
  initialState,
  reducers: {
    changeAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
    changeAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    changeUserName: (state, action) => {
      state.userName = action.payload;
    },
    changeUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    changeUserImage: (state, action) => {
      state.userImage = action.payload;
    },
    changeCurrentUser : (state,action ) =>{
      state.currentUser = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { changeAuthenticated, changeCurrentUser,changeAccessToken, changeUserName, changeUserEmail, changeUserImage } = MSALSlice.actions;

export default MSALSlice.reducer;