import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react"
import ReactDatePicker from "react-datepicker"
import moment from "moment"
import "./NewTimesheet.scss"
import Loader from "../../components/Loader/Loader"
import { insertUpdateTimesheets, deleteTimeSheet, getAllFunctions, getAllServiceCategories, getAllTimeSheets, getProjects, getServiceDesiplines, insertUpdateTimesheetsForMobile } from "../../services/ApiServices"
import { handleError } from "../../const"
import swal from "sweetalert"
import CustomHoursInput from "../../components/CustomHoursInput/CustomHoursInput"
import toast from "react-hot-toast"
import $ from 'jquery';
import Select from 'react-select'
import { useSelector } from "react-redux"

const Timesheet = () => {
    const currentUser = useSelector((state) => state.MSALAuth.currentUser)
    const [isLoading, setIsLoading] = useState(false)

    const [calendarDate, setCalendarDate] = useState(moment().format("YYYY-MM-DD"))

    const [allTimesheetsResponse, setAllTimesheetsResponse] = useState([])
    const [allTimesheets, setAllTimesheets] = useState([])
    const [allProjects, setAllProjects] = useState([])
    const [allServiceCategories, setAllServiceCategories] = useState([])
    const [allFunctions, setAllFunctions] = useState([])
    const [allServiceDisciplines, setAllServiceDisciplines] = useState([])

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 480);

    // Keep watch on window width
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [error, setError] = useState({
        Status: false,
        Indexes: []
    })

    const weekDates = useMemo(() => {
        let dates = []

        const weekStart = isMobileView
            ? moment(calendarDate).format("YYYY-MM-DD")
            : moment(calendarDate).startOf("isoWeek").format("YYYY-MM-DD")
        Array.from(Array(isMobileView ? 1 : 7), (_, i) => i + 1)?.forEach((_, i) => {
            dates.push(moment(weekStart).add(i, "d").format("YYYY-MM-DD"))
        })

        return dates
    }, [calendarDate, isMobileView])

    // Input Time
    const handleOnTimeInput = (timesheet, Key, Value) => {
        const regex = new RegExp(`^[0-9,.-]+$`)

        if (Value !== "") {
            if (Value?.length > 0) {
                if (regex.test(Value)) {
                    setError((prev) => ({ ...prev, Indexes: prev?.Indexes?.filter((i) => i?.IndexId !== timesheet?.IndexId) }))
                    setAllTimesheets((prev) => prev?.map((data) => ({
                        ...data,
                        Hour: timesheet?.IndexId === data?.IndexId && timesheet?.IsMobile === data?.IsMobile ? Value.length > 0 ? Value.replace(",", ".") : "" : data?.Hour
                    })))
                }
            } else {
                setError((prev) => ({ ...prev, Indexes: prev?.Indexes?.filter((i) => i?.IndexId !== timesheet?.IndexId) }))
                setAllTimesheets((prev) => prev?.map((data) => ({
                    ...data,
                    Hour: timesheet?.IndexId === data?.IndexId && timesheet?.IsMobile === data?.IsMobile ? Value.length > 0 ? Value.replace(",", ".") : "" : data?.Hour
                })))
            }
        } else {
            setError((prev) => ({ ...prev, Indexes: prev?.Indexes?.filter((i) => i?.IndexId !== timesheet?.IndexId) }))
            setAllTimesheets((prev) => prev?.map((data) => ({
                ...data,
                Hour: timesheet?.IndexId === data?.IndexId && timesheet?.IsMobile === data?.IsMobile ? Value : data?.Hour
            })))
        }
    }

    // Check value change
    const isValueChange = (timesheet) => {
        const data = allTimesheetsResponse?.filter((d) => d?.Sequence === timesheet?.Sequence && d?.Date === timesheet?.Date && d?.Hour === timesheet?.Hour)
        return data.length > 0 ? true : false
    }

    // Auto Save on Time Change
    const handleBlurOnTimeInput = (timesheet) => {
        if (timesheet?.Hour !== "") {
            if (isValueChange(timesheet) === false) {

                const hasDot = timesheet?.Hour !== 0 ? timesheet?.Hour.includes(".") : false
                const hasComma = timesheet?.Hour !== 0 ? timesheet?.Hour.includes(",") : false

                if (timesheet?.Hour !== 0 && (hasDot || hasComma)) {
                    const time = timesheet?.Hour.split(hasDot ? "." : ",")
                    const Int = Number(time[0])
                    const Des = time[1]?.length > 0 ? Number(time[1]) : undefined

                    const isValidInt = Int >= 0 && Int <= 999
                    const isValidDes = Des !== undefined && time[1]?.toString()?.length <= 2
                    // const isValidDes = Des !== undefined && time[1]?.toString()?.length <= 2 && (Des === 0 || Des === 0o0 || Des === 25 || Des === 5 || Des === 50 || Des === 75)

                    if (isValidInt && isValidDes) {
                        return true
                    } else {
                        if (!isValidInt) {
                            return false
                        } else {
                            return false
                        }
                    }
                } else {
                    if (timesheet?.Hour <= 999) {
                        return true
                    } else {
                        return false
                    }
                }
            } else {
                return true
            }
        }
    }

    // Remove Duplicate Sequence
    const removeDupe = (timesheet) => {
        let unique = [];

        if (timesheet?.length > 0) {
            timesheet?.forEach((e) => {
                if (!unique.includes(e?.Sequence)) {
                    unique.push(e?.Sequence);
                }
            });
        } else {
            unique.push(1)
        }
        return unique;
    }

    const handleAddRow = (date) => {
        setAllTimesheets((prev) => [
            ...prev,
            {
                "IndexId": Math.floor(100000 + Math.random() * 900000) + prev?.length,
                "Id": 0,
                "Date": date,
                "Hour": "",
                "ProjectId": 0,
                "ServiceCategoriesId": 0,
                "ServiceDisciplinesId": 0,
                "FunctionId": 0,
                "Comment": "",
                "Sequence": allTimesheets.length > 0 ? Math.max(...removeDupe(allTimesheets)) + 1 : 1,
                "User": currentUser?.Data?.Email,
                "Hours": "",
                "IsMobile": true,
                "IsSubmited": false,
            },
        ])
    }

    const calculateTotalHours = (data) => {
        return data.reduce((totalHours, entry) => {
            const hours = entry.Hour !== '' ? parseFloat(entry.Hour) : 0;
            return totalHours + hours;
        }, 0);
    };

    const totalWeekHours = calculateTotalHours(allTimesheets);

    const handleDateChange = (direction) => {
        if (direction === "prev") {
            setCalendarDate((prev) => moment(prev).subtract(isMobileView ? 1 : 7, "d").format("YYYY-MM-DD"));
        } else if (direction === "next") {
            setCalendarDate((prev) => moment(prev).add(isMobileView ? 1 : 7, "d").format("YYYY-MM-DD"));
        } else {
            setCalendarDate(moment()._d);
        }
    };

    const handleOnChange = (RegistrationIndex, Key, Value) => {
        setError((prev) => ({ ...prev, Indexes: prev?.Indexes?.filter((i) => i?.IndexId !== RegistrationIndex) }))
        setAllTimesheets((prev) => prev?.map((timeSheet) => ({
            ...timeSheet,
            [Key]: RegistrationIndex === timeSheet?.IndexId ? Value : timeSheet[Key]
        })))
    }

    const renderGroupByDateTimeSheet = (date, dateIndex) => {
        const filteredTimeSheetWithDate = allTimesheets?.length > 0
            ? allTimesheets?.filter((timeSheet) => moment(timeSheet?.Date).format("YYYY-MM-DD") === date)
            : [];

        if (filteredTimeSheetWithDate?.length === 0) {
            setAllTimesheets((prev) => [
                ...prev,
                {
                    "Id": 0,
                    "IndexId": Math.floor(100000 + Math.random() * 900000) + prev?.length,
                    "Date": date,
                    "Hours": "",
                    "ProjectId": 0,
                    "ServiceCategoriesId": 0,
                    "ServiceDisciplinesId": 0,
                    "FunctionId": 0,
                    "Comment": "",
                    "Hour": "",
                    "IsMobile": true,
                    "IsSubmited": false,
                    "Sequence": 1,
                    "User": currentUser?.Data?.Email,
                },
            ])
        }

        return (
            <div key={dateIndex} className="tbl-row mb-4">
                {/* Row Header */}
                {filteredTimeSheetWithDate?.length > 0 && (
                    filteredTimeSheetWithDate?.map((timesheet, timeSheetIndex) =>
                        <div key={`${dateIndex}.${timeSheetIndex}`} className="tbl-date d-flex align-items-center w-100 mb-2">
                            <div className="d-flex align-items-center flex-fill">
                                {/* Hours */}
                                <div className="input-col-Hours">
                                    <span className="label">Hours</span>
                                    <input
                                        className={`form-control form2`}
                                        value={timesheet?.Hour}
                                        id={`${timesheet?.IndexId}-${moment(timesheet?.Date).format("YYYY-MM-DD")}`}
                                        onChange={(e) => handleOnTimeInput(timesheet, "Hour", e.target.value)}
                                        onBlur={(e) => handleBlurOnTimeInput(timesheet)}
                                        disabled={allTimesheets[0]?.IsSubmited}
                                        type="text"
                                        maxLength="6"
                                        autoComplete="off"
                                    />
                                </div>

                                {/* Function */}
                                <div className="input-col-Function">
                                    <span className="label">Function</span>
                                    <Select
                                        value={allFunctions?.length > 0 && timesheet?.FunctionId && allFunctions?.find((func) => func?.Id === timesheet?.FunctionId)?.Id ? ({
                                            value: timesheet?.FunctionId,
                                            label: allFunctions?.find((func) => func?.Id === timesheet?.FunctionId)?.Name
                                        }) : (null)}
                                        onChange={(e) => {
                                            handleOnChange(timesheet?.IndexId, "FunctionId", Number(e.value))
                                            handleOnChange(timesheet?.IndexId, "ServiceCategoriesId", 0)
                                            handleOnChange(timesheet?.IndexId, "ServiceDisciplinesId", 0)
                                        }}
                                        options={allFunctions?.length > 0 &&
                                            allFunctions.map((func) => (
                                                ({ value: func?.Id, label: func?.Name })
                                            ))
                                        }
                                        className={`form-control p-0${error?.Status && error?.Indexes?.some((d) => d?.IndexId === timesheet?.IndexId) && !error?.Indexes?.find((d) => d?.IndexId === timesheet?.IndexId)?.hasFunctionId ? " error" : ""}`}
                                        placeholder="Select"
                                        isDisabled={allTimesheets[0]?.IsSubmited}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                marginTop: 2,
                                                border: "none",
                                                boxShadow: "none",
                                            }),
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>

                                {/* Service Category */}
                                <div className="input-col-Category">
                                    <span className="label">Service category</span>
                                    <Select
                                        value={allServiceCategories?.length > 0 && timesheet?.ServiceCategoriesId && allServiceCategories?.find((cate) => cate?.Id === timesheet?.ServiceCategoriesId)?.Id ? ({
                                            value: timesheet?.ServiceCategoriesId,
                                            label: allServiceCategories?.find((cate) => cate?.Id === timesheet?.ServiceCategoriesId)?.ServiceCategoriesName
                                        }) : (null)}
                                        onChange={(e) => {
                                            handleOnChange(timesheet?.IndexId, "ServiceCategoriesId", Number(e.value))
                                            handleOnChange(timesheet?.IndexId, "ServiceDisciplinesId", 0)
                                        }}
                                        options={allServiceCategories?.length > 0 && (
                                            (timesheet?.FunctionId
                                                ? allServiceCategories?.filter((cate) => cate?.FunctionId === timesheet?.FunctionId)
                                                : allServiceCategories
                                            )?.map((category) => (
                                                ({ value: category?.Id, label: category?.ServiceCategoriesName })
                                            ))
                                        )}
                                        className={`form-control p-0${error?.Status && error?.Indexes?.some((d) => d?.IndexId === timesheet?.IndexId) && !error?.Indexes?.find((d) => d?.IndexId === timesheet?.IndexId)?.hasServiceCategoriesId ? " error" : ""}`}
                                        placeholder="Select"
                                        isDisabled={allTimesheets[0]?.IsSubmited}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                marginTop: 2,
                                                border: "none",
                                                boxShadow: "none",
                                            }),
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>

                                {/* Service Discipline */}
                                <div className="input-col-Discipline">
                                    <span className="label">Service discipline</span>
                                    <Select
                                        value={allServiceDisciplines?.length > 0 && timesheet?.ServiceDisciplinesId && allServiceDisciplines?.find((discipline) => discipline?.Id === timesheet?.ServiceDisciplinesId)?.Id ? ({
                                            value: timesheet?.ServiceDisciplinesId,
                                            label: allServiceDisciplines?.find((discipline) => discipline?.Id === timesheet?.ServiceDisciplinesId)?.ServiceDisciplinesName
                                        }) : (null)}
                                        onChange={(e) => handleOnChange(timesheet?.IndexId, "ServiceDisciplinesId", Number(e.value))}
                                        options={allServiceDisciplines.length > 0 && (
                                            (timesheet?.ServiceCategoriesId
                                                ? allServiceDisciplines?.filter((discipline) => discipline?.ServiceCategoriesId === timesheet?.ServiceCategoriesId)
                                                : allServiceDisciplines
                                            )?.map((discipline) => (
                                                ({ value: discipline?.Id, label: discipline?.ServiceDisciplinesName })
                                            ))
                                        )}
                                        className={`form-control p-0${error?.Status && error?.Indexes?.some((d) => d?.IndexId === timesheet?.IndexId) && !error?.Indexes?.find((d) => d?.IndexId === timesheet?.IndexId)?.hasServiceDisciplinesId ? " error" : ""}`}
                                        placeholder="Select"
                                        isDisabled={allTimesheets[0]?.IsSubmited}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                marginTop: 2,
                                                border: "none",
                                                boxShadow: "none",
                                            }),
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>

                                {/* Project */}
                                <div className="input-col-Project">
                                    <span className="label">Project</span>
                                    <Select
                                        value={allProjects?.length > 0 && timesheet?.ProjectId && allProjects?.find((pro) => pro?.Id === timesheet?.ProjectId)?.Id ? ({
                                            value: timesheet?.ProjectId,
                                            label: allProjects?.find((pro) => pro?.Id === timesheet?.ProjectId)?.ProjectName
                                        }) : (null)}
                                        onChange={(e) => handleOnChange(timesheet?.IndexId, "ProjectId", Number(e.value))}
                                        options={allProjects?.length > 0 &&
                                            allProjects?.map((pro) => (
                                                ({ value: pro?.Id, label: pro?.ProjectName })
                                            ))
                                        }
                                        className={`form-control p-0${error?.Status && error?.Indexes?.some((d) => d?.IndexId === timesheet?.IndexId) && !error?.Indexes?.find((d) => d?.IndexId === timesheet?.IndexId)?.hasProjectId ? " error" : ""}`}
                                        placeholder="Select"
                                        isDisabled={allTimesheets[0]?.IsSubmited}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                marginTop: 2,
                                                border: "none",
                                                boxShadow: "none",
                                            }),
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>

                                {/* Comment */}
                                <div className="input-col-Comment">
                                    <span className="label">Comment</span>
                                    <input
                                        type="text"
                                        className="form-control p-2"
                                        disabled={allTimesheets[0]?.IsSubmited}
                                        value={timesheet?.Comment ?? ""}
                                        onChange={(e) => handleOnChange(timesheet?.IndexId, "Comment", e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Delete */}
                            <div className="icon-col-Delete">
                                <button
                                    className="form-control delete-btn text-center p-2"
                                    onClick={() => deleteTimesheet(timesheet)}
                                    disabled={allTimesheets[0]?.IsSubmited}
                                >
                                    <i className="fa-solid fa-trash-can" />
                                </button>
                            </div>
                        </div>
                    )
                )}

                {/* Row Footer */}
                <div className="row-footer d-flex align-items-center justify-content-between w-100 mt-3">
                    <div className="footer-start d-flex align-items-center">
                        <button
                            onClick={() => handleAddRow(date)}
                            className="add-row btn me-4 p-0"
                            disabled={allTimesheets[0]?.IsSubmited}
                        >
                            <i className="fa-solid fa-plus me-1" />
                            Add new row
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const CustomDatePicker = forwardRef(({ value, onClick }, ref) =>
        <div className="cal-icon">
            <button type="submit" onClick={onClick} ref={ref} className="btn btn-primary-w btn-icon2 cal-btn">
                <i className="fa-regular fa-calendar" />
            </button>
        </div>
    );

    const scrollToDiv = () => {
        var main = document.getElementById("pagetitle");
        main && main?.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    // Get Project, Categories, Disiplines, Teams
    useEffect(() => {
        let subscribed = true;

        setIsLoading(true)
        setAllProjects([])
        setAllServiceCategories([])
        setAllFunctions([])
        setAllServiceDisciplines([])

        Promise.all([getProjects(), getAllServiceCategories(), getServiceDesiplines(), getAllFunctions()]).then((response) => {
            if (subscribed) {
                const projectResponse = response[0]
                const serviceCategoryResponse = response[1]
                const serviceDisiplineResponse = response[2]
                const teamsResponse = response[3]

                if (typeof projectResponse?.Data === "object" && projectResponse?.Data?.length >= 0) {
                    setAllProjects(projectResponse?.Data)
                } else {
                    handleError(projectResponse)
                }

                if (typeof serviceCategoryResponse?.Data === "object" && serviceCategoryResponse?.Data?.length >= 0) {
                    setAllServiceCategories(serviceCategoryResponse?.Data)
                } else {
                    handleError(serviceCategoryResponse)
                }

                if (typeof serviceDisiplineResponse?.Data === "object" && serviceDisiplineResponse?.Data?.length >= 0) {
                    setAllServiceDisciplines(serviceDisiplineResponse?.Data)
                } else {
                    handleError(serviceDisiplineResponse)
                }

                if (typeof teamsResponse?.Data === "object" && teamsResponse?.Data?.length >= 0) {
                    setAllFunctions(teamsResponse?.Data)
                } else {
                    handleError(teamsResponse)
                }
            }
        }).catch((error) => {
            if (subscribed) {
                handleError(error)
            }
        }).finally(() => {
            if (subscribed) {
                setIsLoading(false);
            }
        })
    }, [])

    // Get Timesheets
    const GetAllTimesheets = async () => {
        setIsLoading(true)
        await getAllTimeSheets(isMobileView ? weekDates[0] : moment(calendarDate).startOf("isoWeek").format("YYYY-MM-DD"), isMobileView ? weekDates?.at(-1) : moment(calendarDate).endOf("isoWeek").format("YYYY-MM-DD"), currentUser?.Data?.Email).then((response) => {
            if (typeof response?.Data === "object") {
                const timeSheetsData = response?.Data?.TimeSheet.length > 0 ? response?.Data?.TimeSheet?.map((timeSheet, timesheetIndex) => ({
                    ...timeSheet,
                    IndexId: timesheetIndex,
                    Date: moment(timeSheet?.Date).format("YYYY-MM-DD"),
                })) : []
                setAllTimesheetsResponse(timeSheetsData)
                setAllTimesheets(timeSheetsData)
            } else {
                handleError(response)
            }
        }).catch((error) => {
            console.warn('error', error)
        }).finally(() => {
            setIsLoading(false)
        });
    }

    // Update All
    const addUpdateAllTimesheets = async () => {
        const checkIsAnyBlank = (keys, object) => {
            return keys?.some((key) => !object[key])
        }

        const checkIsAnyFilled = (keys, object) => {
            return keys?.some((key) => object[key])
        }

        const inValidTimesheet = allTimesheets.length > 0 && allTimesheets?.filter((d) =>
            checkIsAnyFilled(
                allServiceCategories?.filter((cate) => cate?.Id === d?.ServiceCategoriesId)[0]?.ProjectSelection ?
                    Object?.keys({
                        FunctionId: d?.FunctionId,
                        Hour: d?.Hour,
                        ProjectId: d?.ProjectId,
                        ServiceCategoriesId: d?.ServiceCategoriesId,
                        ServiceDisciplinesId: d?.ServiceDisciplinesId
                    })
                    : Object?.keys({
                        FunctionId: d?.FunctionId,
                        Hour: d?.Hour,
                        ServiceCategoriesId: d?.ServiceCategoriesId,
                        ServiceDisciplinesId: d?.ServiceDisciplinesId
                    }),
                d
            ) ? checkIsAnyBlank(
                allServiceCategories?.filter((cate) => cate?.Id === d?.ServiceCategoriesId)[0]?.ProjectSelection ?
                    Object?.keys({
                        FunctionId: d?.FunctionId,
                        Hour: d?.Hour,
                        ProjectId: d?.ProjectId,
                        ServiceCategoriesId: d?.ServiceCategoriesId,
                        ServiceDisciplinesId: d?.ServiceDisciplinesId
                    })
                    : Object?.keys({
                        FunctionId: d?.FunctionId,
                        Hour: d?.Hour,
                        ServiceCategoriesId: d?.ServiceCategoriesId,
                        ServiceDisciplinesId: d?.ServiceDisciplinesId
                    }),
                d
            ) : false
        )

        if (inValidTimesheet?.length > 0) {
            setError({
                Status: true,
                Indexes: inValidTimesheet?.map((d) => ({
                    IndexId: d?.IndexId,
                    hasHour: d?.Hour ? true : false,
                    hasServiceCategoriesId: d?.ServiceCategoriesId ? true : false,
                    hasFunctionId: d?.FunctionId ? true : false,
                    hasServiceDisciplinesId: d?.ServiceDisciplinesId ? true : false,
                    hasProjectId: d?.ProjectId ? true : false,
                }))
            })
        } else {
            setError({
                Status: false,
                Indexes: []
            })
        }

        if (inValidTimesheet?.length === 0) {
            if (allTimesheets?.filter((t) => t?.Hour).length > 0) {
                let data = allTimesheets?.length > 0 && allTimesheets?.filter((t) => t?.Hour)

                let payloadData = data.length > 0 && data?.map((d) => d?.Id !== 0
                    ? ({
                        "Id": d?.Id,
                        "Date": d?.Date,
                        "Hour": d?.Hour,
                        "ProjectId": d?.ProjectId,
                        "ServiceCategoriesId": d?.ServiceCategoriesId,
                        "ServiceDisciplinesId": d?.ServiceDisciplinesId,
                        "FunctionId": d?.FunctionId,
                        "Comment": d?.Comment,
                        "Sequence": d?.Sequence,
                        "User": d?.User
                    }) : ({
                        "Date": d?.Date,
                        "Hour": d?.Hour,
                        "ProjectId": d?.ProjectId,
                        "ServiceCategoriesId": d?.ServiceCategoriesId,
                        "ServiceDisciplinesId": d?.ServiceDisciplinesId,
                        "FunctionId": d?.FunctionId,
                        "Comment": d?.Comment,
                        "Sequence": d?.Sequence,
                        "User": d?.User
                    })
                )

                let isValidHour = data.length > 0 && !data?.some((d) => !handleBlurOnTimeInput(d))

                if (isValidHour) {
                    setIsLoading(true);
                    insertUpdateTimesheetsForMobile(payloadData).then((response) => {
                        if (typeof response?.Data === "object" && response?.Success) {
                            toast.success("Data saved successfully!")
                            GetAllTimesheets()
                        } else {
                            toast.error(response?.Message)
                        }
                    }).catch((error) => {
                        console.warn('error', error)
                    }).finally(() => {
                        setIsLoading(false)
                    })
                } else {
                    toast.error("Please enter Hour integer upto 999 and decimal upto 99!")
                }
            } else {
                toast.error("No timesheets to save!")
            }
        } else {
            if (allTimesheets?.filter((t) => !t?.Hour).length > 0) {
                let isValidHour = inValidTimesheet.length > 0 && !inValidTimesheet?.some((d) => handleBlurOnTimeInput(d))
                if (!isValidHour) {
                    toast?.error("Please enter hours!")
                } else {
                    toast.error("Please enter Hour integer upto 999 and decimal upto 99!")
                }
            } else {
                let isValidHour = inValidTimesheet.length > 0 && !inValidTimesheet?.some((d) => handleBlurOnTimeInput(d))
                if (isValidHour) {
                    toast.error("Please enter Hour integer upto 999 and decimal upto 99!")
                }
            }
        }
    }

    // Submit All
    const submitAllTimesheets = async () => {
        const payload = allTimesheets?.map((res) => ({
            ...res,
            IsSubmited: true
        }))

        if (payload.length > 0) {
            swal({
                title: "Are you sure?",
                text: "You will not be able to unsubmit this timesheets!",
                icon: "warning",
                buttons: ['Cancel', 'Yes, submit it!'],
                className: "custom-swal",
                successMode: true,
            }).then((willSubmit) => {
                if (willSubmit) {
                    setIsLoading(true);
                    insertUpdateTimesheets(payload).then((response) => {
                        if (typeof response?.Data === "object" && response?.Success) {
                            toast.success("Data saved successfully!")
                            GetAllTimesheets()
                        } else {
                            toast.error(response?.Message)
                        }
                    }).catch((error) => {
                        console.warn('error', error)
                    }).finally(() => {
                        setIsLoading(false)
                    });
                }
            })
        } else {
            toast.error("No data to submit!")
        }
    }

    // Delete
    const deleteTimesheet = (timesheet) => {
        if (timesheet?.Id === 0) {
            if (allTimesheets?.filter((t) => t?.Date === timesheet?.Date).length > 1) {
                setAllTimesheets(allTimesheets.filter((t) => t?.IndexId !== timesheet?.IndexId))
            } else {
                handleOnChange(timesheet?.IndexId, "Hour", "")
                handleOnChange(timesheet?.IndexId, "FunctionId", 0)
                handleOnChange(timesheet?.IndexId, "ServiceCategoriesId", 0)
                handleOnChange(timesheet?.IndexId, "ServiceDisciplinesId", 0)
                handleOnChange(timesheet?.IndexId, "ProjectId", 0)
                handleOnChange(timesheet?.IndexId, "Comment", "")
            }
        } else {
            let payload = [];
            payload.push(timesheet?.Id)
            swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this timesheet!',
                icon: 'warning',
                buttons: ['Cancel', 'Yes, delete it!'],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    setIsLoading(true);
                    deleteTimeSheet(payload).then(async (response) => {
                        if (response?.Success) {
                            GetAllTimesheets()
                        } else {
                            toast.error(response?.Message)
                        }
                    }).catch((error) => {
                        console.log(error);
                    }).finally(() => {
                        setIsLoading(false);
                    })
                } else {
                    setIsLoading(false);
                }
            });
        }
    }

    useEffect(() => {
        let subscribed = true;

        if (weekDates[0] && weekDates?.at(-1)) {
            setIsLoading(true)
            getAllTimeSheets(isMobileView ? (weekDates, weekDates) : weekDates[0], weekDates?.at(-1), currentUser?.Data?.Email).then((response) => {
                if (subscribed) {
                    if (typeof response?.Data === "object") {
                        const timeSheetsData = response?.Data?.TimeSheet.length > 0 ? response?.Data?.TimeSheet?.map((timeSheet, timesheetIndex) => ({
                            ...timeSheet,
                            IndexId: timesheetIndex,
                            Date: moment(timeSheet?.Date).format("YYYY-MM-DD"),
                        })) : []
                        setAllTimesheetsResponse(timeSheetsData)
                        setAllTimesheets(timeSheetsData)
                    } else {
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    console.warn('error', error)
                }
            }).finally(() => {
                setIsLoading(false)
            });
        } else {
            if (subscribed) {
                setAllTimesheetsResponse([])
                setAllTimesheets([])
            }
        }

        return () => {
            subscribed = false;
        }
    }, [weekDates[0], weekDates?.at(-1)])

    // Hide & Show JQuery
    isMobileView && $(window).on("scroll", function () {
        if ($(this).scrollTop() > 50) {
            $('.back-to-top').fadeIn();
        } else {
            $('.back-to-top').fadeOut();
        }
    });

    return (
        <>
            {isLoading && <Loader />}

            <div className="timesheet">
                <main id="main" className="timesheet-main">
                    <div className="pagetitle mb" id="pagetitle">
                        <h1>Timeregistration</h1>
                    </div>
                    <section className="section">
                        {/* Date Header */}
                        <div className="calendar-header mb-3">
                            <div className="calendar input-group d-flex align-items-center">
                                <button
                                    type="button"
                                    onClick={() => handleDateChange("prev")}
                                    className="input-group-prepend btn btn-sm btn-icon-left"
                                    title="Previous day"
                                >
                                    <i className="fa-solid fa-chevron-left" />
                                </button>

                                <div className="px-3 py-2">
                                    <h6 className="mb-0 fw-medium text-dark text-center">
                                        {isMobileView
                                            ? moment(weekDates[0]).format("DD-MM-YYYY")
                                            : <>
                                                {moment(calendarDate).startOf("isoWeek").format("MMM DD")}
                                                {" - "}
                                                {moment(calendarDate).startOf("isoWeek").format("MMM") !== moment(calendarDate).endOf("isoWeek").format("MMM")
                                                    ? moment(calendarDate).endOf("isoWeek").format("MMM DD")
                                                    : moment(calendarDate).endOf("isoWeek").format("DD")
                                                }
                                                {", "}
                                                {moment(calendarDate).year()}
                                                {", Week "}
                                                {moment(calendarDate).isoWeek()}
                                            </>
                                        }
                                    </h6>
                                </div>

                                <button
                                    type="button"
                                    onClick={() => handleDateChange("next")}
                                    className="input-group-append btn btn-sm btn-icon-right"
                                    title="Next day"
                                >
                                    <i className="fa-solid fa-chevron-right" />
                                </button>
                            </div>
                        </div>

                        {/* Main Body */}
                        <div className="custom-table row mt-5">
                            {weekDates?.length > 0 &&
                                weekDates?.map((date, dateIndex) => renderGroupByDateTimeSheet(date, dateIndex))
                            }
                        </div>
                    </section>
                </main>

                {/* Save Footer */}
                <div className="submit-footer d-flex justify-content-between align-itmes-center" id="submit-footer">
                    <div className="total-hours d-inline-flex justify-content-start align-itmes-center">
                        <span key={totalWeekHours} className="me-3 line-sm">{totalWeekHours.toFixed(2)}</span>
                        <div>
                            <div className="text-md">Total Hours</div>
                            <div className="text-sm txt-gray">
                                {isMobileView
                                    ? moment(weekDates[0]).format("DD-MM-YYYY")
                                    : <>
                                        {"Week "}
                                        {moment(calendarDate).isoWeek()}
                                        {", "}
                                        {moment(calendarDate).year()}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="save text-end">
                        <button className="save-btn btn btn-primary btn-lg btn" onClick={() => addUpdateAllTimesheets()} disabled={allTimesheets[0]?.IsSubmited}>
                            Save
                        </button>
                    </div>
                </div>

                {/* Baack To Top Btn */}
                {isMobileView && (
                    <div
                        onClick={() => scrollToDiv()}
                        className="back-to-top justify-content-center align-items-center"
                    >
                        <i className="fa-solid fa-arrow-up" />
                    </div>
                )}
            </div>
        </>
    )
}

export default Timesheet