import React from "react"
import { Link } from "react-router-dom"
import errorImage from "../../assets/img/404.png"

export default function Error({ Status, Message }) {
    return (
        <div className="container">
            <section className="error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
                <h1 className="mb-3">{Status}</h1>
                <h5>{Message ? Message : "The page you are looking for doesn't exist."}</h5>
                {Status === 404 && <Link className="btn" to="/">Back to home</Link>}
                {Status === 400 && <img src={errorImage} className="img-fluid" alt="unauthorized" />}
            </section>
        </div>
    )
}
