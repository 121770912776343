import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "./UserImage.scss"
import { getProfileImages } from '../../services/ApiServices'
import { changeAllUsersImage } from '../../Store/Slices/APIRespSlice'

import profile from "../../assets/img/img-placeholder.png"

export const UserImage = ({ className, email, size, border }) => {
    const allUsersImage = useSelector((state) => state.APIResponse.AllUsersImages)
    const [userImage, setUserImage] = useState("")

    const dispatch = useDispatch()

    const getImage = (email) => {
        getProfileImages(email).then((response) => {
            dispatch(changeAllUsersImage((prev) => ({ ...prev, [email]: typeof response === "string" ? response : "" })))
            setUserImage(response ?? "")
        }).catch((error) => {
            console.warn("error", error)
        })
    }

    useEffect(() => {
        if (allUsersImage[email] === undefined) {
            getImage(email)
            dispatch(changeAllUsersImage((prev) => ({ ...prev, [email]: "" })))
        } else {
            setUserImage(allUsersImage[email] ?? "")
        }
    }, [allUsersImage, email])

    return (
        <img
            className={className}
            src={userImage ? userImage : profile}
            alt="Profile"
            style={{ height: size, width: size, border: border }}
        />
    )
}
