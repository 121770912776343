import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import iconClock from "../../assets/img/icon-clock.svg"
import iconAdministration from "../../assets/img/icon-settings.svg"
import iconDown from "../../assets/img/icon-chevron-down.svg"
import iconApprove from '../../assets/img/icon-approve.svg'
import iconReport from "../../assets/img/icon-report.svg"
import iconPeriod from "../../assets/img/icon-period.svg"
import "./Sidebar.scss"

export default function Sidebar() {
    let location = useLocation();
    const navigate = useNavigate()
    const path = location.pathname

    return (
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                <li className="nav-item">
                    <div onClick={() => navigate("/")} className={`nav-link ${(path === "/timesheet") ? "active" : "collapsed"}`} >
                        <i className="icon"><img src={iconClock} alt="" /></i>
                        <span>Registration</span>
                    </div>
                    <div onClick={() => navigate("/approval")} className={`nav-link ${path === '/approval' ? "active" : "collapsed"}`} >
                        <i className="icon"><img src={iconApprove} alt="" /></i>
                        <span>Approval Status</span>
                    </div>
                    <div onClick={() => navigate("/report", { state: { key: true } })} className={`nav-link ${path === '/report' ? "active" : "collapsed"}`}>
                        <i className="icon icon-report me-4"><img src={iconReport} style={{ color: "red" }} alt="" /></i>
                        <span>Report</span>
                    </div>
                </li>
                <li className="nav-item">
                    <div className="nav-link" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#" >
                        <i className="icon"><img src={iconAdministration} alt="" /></i>
                        <span>Administration</span>
                        <i className="icon ms-auto m-0"><img src={iconDown} alt="" /></i>
                    </div>
                    <ul id="components-nav" className="nav-content collapse show" data-bs-parent="#sidebar-nav">
                        <li className="li-border">
                            <div onClick={() => navigate("/period", { state: { key: true } })} className={`nav-link ${path === '/period' ? "active" : "collapsed"}`}>
                                <i className="icon"><img src="assets/img/icon-clock.svg" alt="" /></i>
                                <span>Period</span>
                            </div>
                        </li>
                        <li className="li-border">
                            <div onClick={() => navigate("/holidays")} className={`nav-link ${path === '/holidays' ? "active" : "collapsed"}`}>
                                <i className="icon"><img src="assets/img/icon-clock.svg" alt="" /></i>
                                <span>Holidays</span>
                            </div>
                        </li>
                        <li className="li-border">
                            <div onClick={() => navigate("/import-timesheet")} className={`nav-link ${path === '/import-timesheet' ? "active" : "collapsed"}`}>
                                <i className="icon"><img src="assets/img/icon-clock.svg" alt="" /></i>
                                <span>Import</span>
                            </div>
                        </li>
                        <li className="li-border">
                            <div onClick={() => navigate("/function")} className={`nav-link ${path === '/function' ? "active" : "collapsed"}`}>
                                <i className="icon"><img src="assets/img/icon-clock.svg" alt="" /></i>
                                <span>Functions</span>
                            </div>
                        </li>
                        <li className="li-border">
                            <div onClick={() => navigate("/service-categories")} className={`nav-link ${path === '/service-categories' ? "active" : "collapsed"}`}>
                                <i className="icon"><img src="assets/img/icon-clock.svg" alt="" /></i>
                                <span>Service Categories</span>
                            </div>
                        </li>
                        <li className="li-border">
                            <div onClick={() => navigate("/service-discipline")} className={`nav-link ${path === '/service-discipline' ? "active" : "collapsed"}`}>
                                <i className="icon"><img src="assets/img/icon-clock.svg" alt="" /></i>
                                <span>Service Disciplines</span>
                            </div>
                        </li>
                        <li className="li-border">
                            <div onClick={() => navigate("/projects")} className={`nav-link ${path === '/projects' ? "active" : "collapsed"}`}>
                                <i className="icon"><img src="assets/img/icon-clock.svg" alt="" /></i>
                                <span>Projects</span>
                            </div>
                        </li>
                        <li className="li-border">
                            <div onClick={() => navigate("/notifications")} className={`nav-link ${path === '/notifications' ? "active" : "collapsed"}`}>
                                <i className="icon"><img src="assets/img/icon-clock.svg" alt="" /></i>
                                <span>Notifications</span>
                            </div>
                        </li>
                        <li className="li-border">
                            <div onClick={() => navigate("/users")} className={`nav-link ${path === '/users' ? "active" : "collapsed"}`}>
                                <i className="icon"><img src="assets/img/icon-clock.svg" alt="" /></i>
                                <span>Users</span>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </aside>
    )
}